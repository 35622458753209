.container {
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
.white-image {
  filter: grayscale(100%) brightness(0) invert(100%);
}
.poppinFont{
  font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
}

.form-control::placeholder{
  color: #3a3a3a;
}
.nav-active-address-tab{
  /* text-decoration: underline; */
  color: #f5b03b;
}
.container1 {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.wp-msg-button {
  display: block;
  position: fixed;
  text-decoration: none;
  z-index: 9999999999;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* transform: scale(0.8); */
  left: 20px;
  bottom: 60px;
  
}


@media screen and (min-width:1200) {
  .chai p{
    font-size: 14px !important;
  }
}

@media screen and (max-width:1199) {
  .chai p{
    font-size: 14px !important;
  }
}


/* In your CSS file or React component */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .wp-call-button {
    display: block;
    position: fixed;
    text-decoration: none;
    z-index: 9999999999;
    width: 55px;
    height: 55px;
    border: 3px solid #ffe1b1;
    border-radius: 50%;
    /* transform: scale(0.8); */
    left: 22px;
    animation: ripple 2s infinite;
    box-shadow: 0 0 0 rgba(255, 198, 113, 0.571);
    bottom: 140px;
    background: url(/public/assets/call-icon.svg)
      center/30px 30px no-repeat #ff9d04 !important;
  }

}
.flag-after {
  content: "";
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  color: #70aa26;
  position: relative;
  text-align: center;
  animation: MoveUpDown 1s linear infinite;
}

.h4 {
  font-size: 30px;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: normal;
  color: #131d2b;
  /* margin: 20px 0; */
  /* margin-bottom: 10px; */
}
.p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
  color: #353536;
}

.scroll-to-top {
  bottom: 60px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 25rem !important;
  transition: 0.4s;
}
.scroll-to-top:hover {
  scale: 1.2;
}

.scroll-to-top:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none !important;
}
@-webkit-keyframes pulse1 {
  to {
    box-shadow: 0 0 0 45px rgba(232, 155, 61, 0);
  }
}
@-moz-keyframes pulse1 {
  to {
    box-shadow: 0 0 0 45px rgba(232, 155, 61, 0);
  }
}
@-ms-keyframes pulse1 {
  to {
    box-shadow: 0 0 0 45px rgba(232, 155, 61, 0);
  }
}
@keyframes pulse1 {
  to {
    box-shadow: 0 0 0 45px rgba(232, 155, 61, 0);
  }
}
.scroll-to-top svg {
  height: 18px;
}
.scroll-to-next {
  position: absolute;
  width: 100%;
  bottom: 0;
  behavior: smooth;
  left: 0;
  right: 0;
  text-align: center;
  /* margin-bottom: 50px; */
  z-index: 2;
}

.scroll-down-type-2.scroll-to-next a {
  width: auto;
  behavior: smooth;
  height: auto;
  background-color: transparent;
  display: inline-block;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.08);
  position: relative;
}
.scroll-to-next a {
  font-size: 18px;
  behavior: smooth;
  border-radius: 100%;
  background-color: #fff;
  height: 45px;
  width: 45px;
  display: inline-block;
  vertical-align: bottom;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.scroll-down-type-2.scroll-to-next .scroll-to-down-text {
  -webkit-transform: rotate(-90deg);
  behavior: smooth;
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-weight: 600;
  color: #232323;
  font-size: 15px;
  margin-bottom: 75px;
  text-align: initial;
  width: 100px;
  letter-spacing: -0.5px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.scroll-down-type-2.scroll-to-next .section-link.after-text:after {
  height: 30px;
  width: 2px;
  behavior: smooth;
  background-color: black;
  content: "";
  position: absolute;
  bottom: 0px;
  right: -2px;
  left: 0;
  margin: 0 auto;
}
a {
  text-decoration: none;
  display: inline-block;
  background-color: transparent;
}

.h1 .h2 .h3 {
  font-weight: 700;
  color: #323b4b;
}
h1 {
  font-weight: 700;
  color: #323b4b;
  font-size: 46px;
  line-height: 1.31;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.info {
  font-size: 18px;
  color: #5f6673;
  margin: 5px 0px;
}
.optionbox {
  width: 50%;
  padding: 5px;
  position: relative;
}
.radiobtns {
  padding: 0px 0px 35px;
}
.radiobtns input {
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  background: #e5f1ff !important;
  border: 1px solid #e5f1ff;
  margin: 0px !important;
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 13px;
}
.radiobtns label {
  color: #5f6673;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  padding-left: 18px;
  margin: 0px;
  cursor: pointer;
}
/* .contact-container{
  background-color: white !important;
}
.contact-container::before{
content: "";
position: absolute;
width: 100%;
height: 100%;
background-color: #e5e1ee;

} */
.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  touch-action: manipulation;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 212px;
  height: 66px;
  padding: 0 42px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.btn_blue {
  background: #1366ff;
  color: #ffffff;
}
a.aboutSolutions:before {
  content: "";
  width: 92px;
  height: 2px;
  background: #5f6673;
  transition: 0.4s;
  position: absolute;
  bottom: 8px;
  left: 54px;
}
a.aboutSolutions {
  cursor: pointer;
  display: block;
  color: #5f6673;
  margin: 35px auto 0px;
  position: relative;
  transition: 0.4s;
}
a.aboutSolutions img {
  width: 45px;
  display: inline-block;
  margin-right: 10px;
  transition: 0.4s;
}
.aboutSolutions:hover {
  color: #1366ff;
}
.covervid {
  width: 100%;
  height: auto;
  border: 0px !important;
  pointer-events: none;
  border-radius: 15px;
  background-color: #dae4f6;
  max-width: 550px;
}
.companies_info {
  margin-bottom: 33px;
  text-align: center;
  color: #5f6673;
  font-size: 24px;
  line-height: 1.20833;
}

.trustedImg img {
  margin: auto;
  max-height: 55px;
}

.navbar-content-3 {
  /* width: 295px; */
  text-align: right;
}
.nav-content-btn-1 {
  border: 1px solid #e5f1ff;
  background-color: #e5f1ff;
  color: #1366ff;
  display: inline-block;
  border-radius: 10px;
  font-weight: 600;
  transition: 0.4s;
  text-transform: capitalize;
  line-height: 1.2;
  font-size: 16px;
  padding: 14px 20px;
}

.nav-content-btn-2 {
  background: #1366ff;
  color: #fff;
  border: 1px solid #1366ff;
  display: inline-block;
  border-radius: 10px;
  font-weight: 600;
  transition: 0.4s;
  text-transform: capitalize;
  line-height: 1.2;
  font-size: 16px;
  padding: 14px 20px;
}
.menuItem {
  padding: 12px 0px 10px 10px;
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 2.43;
  color: #151515;
  position: relative;
  transition: 0.4s;
}

@media screen and (max-height: 300px) {
  ul {
    margin-top: 40px;
  }
}

.largeMenuList {
  left: 0px;
  padding: 20px 0;
  position: absolute;
  background: #ffffff;
  -webkit-box-shadow: 0px 54px 50px rgb(67 67 68 / 7%);
  box-shadow: 0px 54px 50px rgb(67 67 68 / 7%);
  border-radius: 10px;
  display: block;
  margin: 0px;
  height: auto;
}

.menuList {
  left: 0px;
  padding: 20px 0;
  position: absolute;
  background: #ffffff;
  -webkit-box-shadow: 0px 54px 50px rgb(67 67 68 / 7%);
  box-shadow: 0px 54px 50px rgb(67 67 68 / 7%);
  border-radius: 10px;
  display: block;
  margin: 0px;
  height: auto;
}

.menuItem:hover {
  color: #1366ff;
}
.menuList li {
  /* width: 10rem; */
  padding: 0px 15px;
  position: relative;
  display: block;
  max-width: 100%;
}
.menuList li a {
  width: 100%;
  color: #323b4b;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  font-weight: 700;
  transition: 0.4s;
  display: flex;
  padding: 15px;
  border-bottom: 2px solid #fafbfc;
  line-height: 1.3;
}
.menuList li a:hover {
  color: #377dff;
  border-color: #377dff;
}
.menuList li a span {
  line-height: 1.22222;
  display: block !important;
  margin: 0px !important;
  padding: 5px 0 0 0 !important;
  font-size: 14px !important;
  font-weight: 400;
  color: #5f6673;
  font-family: "Inter", sans-serif !important;
}

/* Section 3 */

.techStack {
  padding: 20px 0px;
  clear: both;
}

.h3 {
  font-size: 36px;
  line-height: 1.25;
  font-weight: 700;
  color: #323b4b;
}
.techStack h2 {
  margin: 0px auto 5px;
}
.techStack ul {
  margin: 25px auto 0px;
}
.techStack ul li {
  width: calc(33.33% - 20px);
  cursor: pointer;
  margin: 10px;
  background: #fff;
  transition: 0.4s;
  border-radius: 15px;
  padding: 30px 10px;
  align-items: flex-start;
}
.techStack ul li:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  scale: 1.01;
}
.techStack ul li strong {
  display: block;
  line-height: 1.55;
  font-size: 22px;
  padding: 0px 0px 5px;
}
.techStack ul li span {
  display: block;
  color: #5f6673;
  font-size: 17px;
  line-height: 1.63;
  font-weight: 600;
}
.techStack ul li a {
  margin: 20px auto 0px;
}
.textBlue {
  color: #1366ff;
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  margin: 25px 0px 0px;
  transition: 0.4s;
  border-bottom: 1px solid #0000;
  background: #0000 !important;
}
.textBlue img {
  display: inline-block !important;
  width: 20px;
  margin: 5px !important;
  height: 18px !important;
}
.adjustFlex {
  display: flex;
  flex-wrap: wrap;
  align-items: inherit;
}
.techStack ul li.last-tech {
  align-self: center;
  padding: 30px 0px;
  width: calc(66.66% - 20px);
  border: solid 1px #1366ff;
  position: relative;
}
.offer {
  padding: 40px 0px;
}

/* Section-4 */
.partnerTestimonial {
  position: absolute;
  width: 75px;
  height: 75px;
  background: #1366ff;
  top: 0px;
  bottom: 0px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  display: flex;
  margin: auto;
  left: 0px;
  right: 0px;
}

/* Section-5 */

.service ul {
  margin: 30px auto 10px;
}

.service ul li {
  margin: 8px;
  text-align: center;
}
.service ul li a {
  display: flex;
  background: #fff;
  padding: 35px 15px;
  border: 1px solid #fff;
  transition: 0.4s;
  border-radius: 16px;
}
.service ul li a:hover {
  border: 1px solid #377dff;
  box-shadow: 0 12px 22px 0 rgb(0 0 0 / 7%);
}

.service ul li a span {
  color: #323b4b;
  font-weight: 600;
  font-size: 18px;
  display: inline-block;
  margin: 15px auto auto;
  line-height: 1.4;
}
.service ul li a:hover span {
  color: #377dff;
}

/* Navbar */

/* HamburgerMenu.css */
#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 36px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/* Footer */

.footer {
  padding: 60px 0 35px;
  background-color: #f9f9f9;
}
.footer-wrap {
  flex-shrink: 0;
  width: 275px;
  margin-right: 20px;
}
.footer-text {
  margin-bottom: 35px;
  font-size: 16px;
  color: #5f6673;
}

.footer-row {
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: calc(100% - 295px);
  margin-right: 0px;
  padding-top: 0px;
}
.footer-col {
  margin-right: auto;
  padding: 0px 10px;
}
.footer-category {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 1.30833;
  font-weight: 600;
}
.footer-menu {
  display: flex;
  flex-direction: column;
}
.footer-item {
  font-size: 16px;
  cursor: pointer;
  line-height: 1.2;
  color: #5f6673;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}
.footer-item:not(:last-child) {
  margin-bottom: 19px;
}
.footer-item:hover {
  color: #377dff;
}
.footer-copyright {
  line-height: 1.2222;
  color: #5f6673;
}
.footer p {
  /* font-size: 14px; */
  /* margin-bottom: 20px; */
  font-weight: 400;
  line-height: 1.43;
  color: #404042;
}
.cursive-text {
  letter-spacing: 3px;
  /* font-size: 68px; */
  font-weight: 100;
}
.cover-text-1 {
  color: #0a0a0a;
  white-space: normal;
  /* font-size: 70px; */
  font-weight: bold;
  /* line-height: 95px; */
  letter-spacing: -2px;
  -webkit-text-fill-color: initial;
}
.cover-text-2 {
  /* font-size: 100px; */
  font-weight: 700;
  line-height: 95px;
  letter-spacing: 2px;
  -webkit-text-fill-color: initial;
}
.cover-text-3 {
  white-space: normal;
  cursor: pointer;
  /* margin-top: 5px; */
  font-size: 24px;
  /* padding: 0 10px; */
  font-weight: 700;
  position: relative;
  /* line-height: 95px; */
  margin-bottom: -10px;
  letter-spacing: 1px;
  -webkit-text-fill-color: initial;
}

/* litho title */
.intro-section {
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e4e4e4;
  /* transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; */
}

.primary-title {
  white-space: normal;
  font-size: 100px;
  font-weight: 700;
  line-height: 95px;
  letter-spacing: -5px;
  color: #ff9d04;
  -webkit-text-fill-color: initial;
}
.secondary-title {
  font-size: 100px;
  font-weight: 600;
  line-height: 95px;
  letter-spacing: -5px;
  color: #232323;
  -webkit-text-fill-color: initial;
}

/* 3d Men Section */

.threeD-men-section {
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e4e4e4;
}

/*Contact Forms */
.contact-container {
  position: relative;
  z-index: 9;
  border-radius: 15px;
  /* background-color: #fff; */
  /* box-shadow: 0 54px 50px 0 rgb(67 67 68 / 7%); */
}
.input-container.input-box-container{
padding-top: 15px !important;
}

.input-container h3 {
  text-align: center;

  /* font-weight: 700; */
  color: #323b4b;
  padding: 0px;
  text-transform: inherit;
  margin: 0px;
}
.input-container p {
  color: #5f6673;
  text-align: center;
  padding: 5px 0px 25px;
  line-height: 1.4;
  border: 0px !important;
  margin: 0px;
  font-size: 16px;
}
.contact-part1 h2 {
  color: #fff;
  line-height: 1.35;
  font-weight: 700;
  font-size: 32px;
  text-transform: inherit;
}
.contact-part1 p {
  color: #fff;
  padding: 20px 0px;
  border-bottom: 1px solid #fff;
  line-height: 1.6;
}
.contact-part1 span {
  color: #fff;
  display: block;
  font-size: 14px;
  padding: 35px 0px 15px;
}

.contact-dialog {
  position: relative;
  margin: 10px;
  width: 100%;
  max-width: 100%;
  margin: 10px auto;
}
.contact-dialog::before {
  content: "";
  background: #1366ff url(/public/assets/contact-cover.webp) no-repeat left
    center;
  width: 60%;
  filter: brightness(0.7);
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-size: cover !important;
}
.contact-content {
  z-index: 9;
}

.form-control {
  font-weight: 400;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  /* -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075); */
  /* box-shadow: inset 0 1px 1px rgba(0,0,0,.075); */
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  /* -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6); */
}
.submit-button {
  /* color: black; */
  /* border-width: 1px; */
  /* border-color: #000; */
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  transition: 0.4s;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  text-transform: uppercase;
  background-color: transparent;

  background-image: linear-gradient(
    to bottom right,
    #ffe2b5 0%,
    #fff 40%,
    #fff 60%,
    #ccf8c7 100%
  );
  /* border-width: 0px 0px 0px 0px; */
  padding: 12px 28px 12px 28px;
}
.submit-button:hover {
  scale: 1.04;

  background-image: linear-gradient(
    to bottom right,
    #ccf8c7 0%,
    #fff 40%,
    #fff 60%,
    #ffe2b5 100%
  );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contact-header {
  padding: 30px 60px;
  color: #464646;
  /* color: black!important; */
  /* background-color: #ff3c00 !important; */
  background-image: linear-gradient(
    to bottom right,
    #ccf8c7 0%,
    #fff 40%,
    #fff 60%,
    #ffe2b5 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 48px 35px 40px; */
  border-radius: 3px;
  position: relative;
  z-index: 1;
}
