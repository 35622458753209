@media screen and (max-width: 500px) {
  .service ul li a span {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 767px) {
  .cursive-text {
    letter-spacing: 3px;
    font-size: 40px !important;
    font-weight: 100;
  }
  .cover-text-1 {
    font-size: 28px !important;
    line-height: 48px !important ;
    letter-spacing: -0.5px;
    display: block;
  }
  .cover-text-3 {
    font-size: 16px !important;
    margin-bottom: 5px;
    letter-spacing: -0.5px;
    display: block;
  }
  .cover-text-2 {
    margin-top: 20px;
    font-size: 45px !important;
    line-height: 43px !important;
    letter-spacing: -0.5px;
    display: block;
  }
  .primary-title {
    font-size: 50px !important;
    line-height: 43px !important;
    letter-spacing: -0.55px !important;
  }
  .secondary-title {
    font-size: 50px !important;
    line-height: 43px !important;
    letter-spacing: -0.55px !important;
  }

  .parallax-image {
    width: 100%;
  }
  .parallax-container {
    height: 50vh !important;
  }
  .radiobtns .optionbox {
    width: 100%;
  }
  .banner__btn {
    min-width: 100%;
  }
  .btn {
    width: 100%;
  }
  .footer-col {
    width: 50%;
    padding: 0px 5px;
  }
  .footer-category {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .footer-item:not(:last-child) {
    margin-bottom: 13px;
  }
  .footer-item {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    width: 750px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .container1 {
    width: 750px;
  }
}
@media screen and (max-width: 991px) {
  body {
    font-size: 16px;
  }
  .common-heading-section h3 {
    font-size: 26px !important;
  }
  .clickcustomform.common-git-btn-footer.btnHide {
    bottom: -50px;
  }
  .clickcustomform.common-git-btn-footer.showBtn {
    bottom: 6px;
  }
  .git-btn-footer {
    z-index: 9;
    width: calc(100% - 1.7rem);
    left: 50%;
    transform: translateX(-50%);
    bottom: -50px;
    transition: all 0.3s;
    background-color: #1aa37e !important;
    border-color: #1aa37e !important;
  }
  .common-git-btn-footer {
    display: block;
    display: block;
    left: 50%;
  transform: translateX(-50%);
    /* margin: 0 8px; */
    transition: 0.4s;
    position: fixed;
    width: 98%;
    bottom: -64px;
    text-align: center;
    padding: 11px 30px !important;
    z-index: 99;
    font-size: 16px;
    border: solid 1px #1aa37e;
    background-color: #1aa37e;
    color: #fff !important;
  }
  .cursive-text {
    letter-spacing: 3px;
    font-size: 48px;
    font-weight: 100;
  }
  .cover-text-1 {
    font-size: 40px;
    line-height: 65px;
  }
  .cover-text-2 {
    font-size: 70px;
    line-height: 65px;
  }
  .intro-title {
    text-align: center;
    align-items: center;
  }
  .primary-title {
    font-size: 70px !important;
    line-height: 43px !important;
    letter-spacing: -0.1 !important;
  }

  .secondary-title {
    font-size: 50px;
    line-height: 65px;
  }
  .companies_info {
    font-size: 20px;
    line-height: 1.75;
  }

  nav {
    background-color: white !important;
    padding: 10px 0px 10px !important;
    /* box-shadow: 1px 12px 20px 0px rgb(209 209 209 / 50%); */
  }

  .menuList li {
    width: 100% !important;
  }

  .h1 {
    margin: 15px 0px 0px;
    font-size: 30px !important;
  }
  .info {
    margin: 20px 0px 25px;
    font-size: 16px;
  }
  .btn {
    min-width: 180px;
    height: 60px;
    padding: 0 40px;
  }
  a.aboutSolutions {
    margin: 25px auto 0px;
  }
  .cover-part2 {
    order: 1;
    text-align: center !important;
  }

  .trustedImg img {
    margin: 20px auto;
    max-height: 50px;
  }
  .menuItem {
    display: block;
    padding: 0px !important;
  }

  /* section 3 */
  .techStack {
    padding: 40px 15px;
  }
  .h3 {
    font-size: 26px;
    line-height: 1.2;
  }
  .techStack ul {
    margin: 20px auto 0px;
  }
  .techStack ul li.last-tech,
  .techStack ul li {
    width: calc(100% - 20px) !important;
    padding: 25px 0px;
    justify-content: center;
    text-align: center;
  }
  .techStack ul li strong {
    font-size: 20px !important;
    padding: 10px 0px 5px !important;
  }
  .techStack ul li span {
    font-size: 14px;
  }
  .techStack ul li a {
    margin: 15px auto 0px;
  }
  .offer {
    padding: 40px 0px;
    text-align: center;
  }
  /* Section-5 */
  .service ul {
    justify-content: center;
    margin: 20px auto 0px;
  }
  .service ul li a {
    padding: 20px 10px !important;
  }
  .service ul li a span {
    font-size: 20px;
    margin: 10px auto auto;
  }
  /* Contact form */

  .input-container {
    padding: 27px 15px 15px 15px !important;
    /* margin: 10px auto 0px; */
  }

  .input-container p {
    font-size: 16px;
    padding: 5px 0px 20px;
    display: block;
  }
  .contact-part1 {
    z-index: 9 !important;
  }
  .contact-part1 h2 {
    font-size: 22px !important;
    padding: 10px 0px 0px;
    margin: 0px;
  }
  .contact-part1 p {
    line-height: 1.5;
    font-size: 14px !important;
    padding: 10px 0px 15px;
    display: none;
  }
  .contact-part1 span {
    font-size: 12px !important;
    padding: 20px 0px 10px;
    display: none;
  }
  .contact-dialog::before {
    width: 100% !important;
  }

  /* Footer */
  .footer {
    padding: 45px 0 20px;
  }
  .footer-wrap {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid #f3f3f3;
  }
  .footer-text {
    width: 100%;
    max-width: 100% !important;
    margin-bottom: 25px;
  }
  .footer-row {
    margin-right: 0px;
    flex-wrap: wrap;
    width: 100%;
  }
  .footer-col {
    margin: 15px 0px;
    padding-right: 15px;
  }
  .footer-col:not(:last-child) {
    margin-right: auto;
  }
  .footer-item:not(:last-child) {
    margin-bottom: 15px;
  }
  .footer-copyright {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 992px) {
  .home-banner.web-development-banner:after {
    position: absolute;
    content: "";
    width: 500px;
    height: 500px;
    right: -50px;
    top: 60px;
    z-index: 1;
    opacity: 0.49;
    background-color: #f7f8fe;
    border-radius: 50%;
    border: 70px solid #dbdeee;
  }
  .cursive-text {
    letter-spacing: 3px;
    font-size: 58px;
    font-weight: 100;
  }
  .cover-text-1 {
    font-size: 48px;
    line-height: 65px;
  }
  .cover-text-2 {
    font-size: 100px;
    font-weight: 700;
    line-height: 95px;
    letter-spacing: 2px;
    -webkit-text-fill-color: initial;
  }
  .container {
    width: 970px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .container1 {
    width: 970px;
  }
}
@media screen and (max-width: 1080px) {
  .mega-sub-menu {
    /* width: calc(100% + 200px) !important; */
    /* left: -200px !important; */
  }
}
@media screen and (max-width: 1199px) {
  /* Navbar */
  .input-container {
    padding: 30px 30px 15px 30px;
  }

  .mega-sub-menu {
    /* width: calc(100% + 275px) !important; */
    /* left: -200px !important; */
  }

  /* Contact form */

  /* Footer */
  .footer-wrap {
    width: 100% !important;
    margin-right: 0;
    margin: 0px 0px 20px;
  }
  .footer-text {
    margin-bottom: 20px;
  }
  .footer-row {
    padding-top: 10px;
  }
  .footer-category {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .input-container {
    padding: 30px 30px;
  }
  .container {
    width: 1300px;
  }
  .container1 {
    width: 1170px;
  }
}
@media screen and (max-width: 1280px) {
  .mega-sub-menu {
    width: 100% !important;
    /* left: 60px !important; */
  }
  .navbar-content-3 {
    text-align: left !important;
    padding: 10px 0;
  }
  .menuList {
    position: relative !important;
    left: 0px;
    padding: 0 !important;
    border-radius: 8px;
    margin: 0px 0px 15px;
    background: #ffffff;
    -webkit-box-shadow: 0px 54px 50px rgb(67 67 68 / 7%);
    box-shadow: 0px 54px 50px rgb(67 67 68 / 7%);
    height: 200px !important;
    overflow-y: scroll !important;
  }
  .hamburger {
    position: absolute;
    right: 0px;
    top: 5px;
    transition: 0.4s;
  }
  nav {
    background: white !important;
  }
}
@media screen and (min-width: 1281px) {
  .large-menu {
    left: -450px !important;
  }

  .mega-sub-menu {
    display: grid !important;
    justify-content: space-evenly !important;
    grid-auto-flow: column;
    overflow: auto;
    width: max-content !important;
  }
  .menuItem:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: black;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .menuItem:hover:after {
    width: 100%;
    left: 0;
  }
}
