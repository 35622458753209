.button5 {
    display: inline-block;
    cursor: pointer;
    padding: 0.75rem 1.25rem;
    text-align: center;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    /* font-size: 1rem; */
    letter-spacing: 0.1rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .button5:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1ba37e; /* You'll need to replace $color with the actual color value */
    border-radius: 10rem;
    z-index: -2;
  }
  
  .button5:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #63b59f; /* You'll need to replace $color with the actual color value */
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  
  .button5:hover {
    color: #fff;
  }
  
  .button5:hover:before {
    width: 100%;
  }