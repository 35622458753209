.ui-bg {
  background-image: linear-gradient(to top, #db343a, #010a45) !important;
  padding: calc(4rem + 4vw) 0;
}
.list-addon-home-ui {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
.list-addon-home-ui li img {
  object-fit: scale-down;
  object-position: center left;
  width: 60px;
  height: auto;
  position: absolute;
  left: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.list-addon-home-ui li p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: #a6acd5;
}
.list-addon-home-ui li h4 {
  font-size: 38px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.26;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.odometer-auto-theme {
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1.1em;
  display: inline-block !important;
}
.list-addon-home-ui li {
  margin: 15px;
  padding: 30px 30px 30px 115px;
  position: relative;
  transition: 0.5s;
  width: calc(25% - 30px);
  border-radius: 30px 0px 30px 0;
  border: solid 0.5px #fff !important;
  background-color: transparent;
  padding-right: 15px;
}
.line-overlay {
  position: absolute;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 1;
}
.rotate {
  animation: rotation 8s infinite linear;
}

/* Rocket Launcher */
.rocket-launcher .allurive-side-text p {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #121212;
  margin-bottom: 0px;
}
.rocket-launcher .allurive-side-text ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
}
.allurive-side-text ul {
  list-style: none;
  padding: 0px;
  margin: 20px 0px;
  margin-bottom: 40px;
}
.rocket-launcher .allurive-side-text ul li {
  padding: 15px;
  width: 50%;
  float: left;
  padding-left: 70px;
}
.allurive-side-text ul li {
  font-size: 20px;
  font-family: "SF-Pro-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #232527;
  margin: 20px 0px;
  padding-left: 50px;
  position: relative;
}
.rocket-launcher .allurive-side-text ul li img {
  position: absolute;
  left: 0px;
  width: 50px;
  top: 0px;
  bottom: 0px;
  margin: auto;
}
.rocket-launcher .allurive-side-text h4 {
  line-height: 1.3;
  margin-bottom: 30px;
}
.allurive-side-text h4 {
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #131d2b;
  margin: 0px;
}

/* Allurive Row section */

.allurive-main-section {
  width: 100%;
  position: relative;
  float: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.allurive-main-section .container1 {
  position: absolute;
  margin: auto !important;
  max-width: 1170px;
  left: 0px;
  right: 0px;
  width: 100%;
}

/* Uneven Alignment Section */

.uneven-aligment {
  margin: 0px 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-image: url(/public/assets/arrow-shape-path1.png);
  padding: 100px 0px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-bottom: 60px;
  margin-left: -35px;
  margin-right: -35px;
  padding-right: 35px;
  padding-left: 35px;
}

.uneven-aligment li {
  width: 25%;
  float: left;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
.uneven-aligment li img {
  width: 80px;
  height: 80px;
  background-color: #ff3d43;
  padding: 20px;
  border-radius: 50%;
  margin: 10px 0px;
}
.uneven-aligment li span {
  display: block;
  margin: 10px 0px;
}
.uneven-aligment li span h4 {
  font-size: 22px;
  font-family: "SF-Pro-Semibold";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0px;
}
.uneven-aligment li span i {
  font-family: "SF-Pro-Bold";
  font-size: 72px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #151a3d;
  display: block;
  margin-top: -11px;
}
.uneven-aligment li:nth-child(1) img,
.uneven-aligment li:nth-child(2) img {
  order: 2;
}
.uneven-aligment li:nth-child(1) {
  top: 130px;
}
.uneven-aligment li:nth-child(2) {
  top: 55px;
  right: -30px;
}
.uneven-aligment li:nth-child(3) {
  top: 120px;
  right: -100px;
}
.uneven-aligment li:nth-child(4) {
  top: -80px;
  right: -60px;
}

@media screen and (max-width: 991px) {
  .rocket-launcher .allurive-side-text p {
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
  }
  .allurive-side-text ul li {
    padding: 0px;
    text-align: center;
  }
  .rocket-launcher .allurive-side-text h4 {
    font-size: 20px;
  }
  .allurive-side-text h4 {
    text-align: center;
  }
  .allurive-main-section .container1 {
    position: static;
    margin-top: 30px !important ;
  }
  .main-image-allurive-side {
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 0px 15px;
  }
  ul.uneven-aligment {
    background-image: none;
    padding: 0px;
    margin: 0px;
    margin-top: 30px;
  }
  .uneven-aligment li {
    width: 50%;
    position: static;
    display: block;
  }
  .uneven-aligment li img {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .list-addon-home-ui {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
  }
  .list-addon-home-ui li {
    margin-top: 0;
    margin-bottom: 15px;
    width: calc(50% - 30px) !important;
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media screen and (min-width: 992px) {
  .allurive-side-text ul li:before {
    content: "";
    width: 16px;
    height: 3px;
    background-color: #70aa26;
    position: absolute;
    left: 0px;
    top: 10px;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .uneven-aligment li {
    width: 100%;
  }
  .list-addon-home-ui li {
    width: 100% !important;
    margin: 0;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .list-addon-home-ui li {
    padding: 30px;
    text-align: center;
  }
  .list-addon-home-ui li img {
    position: static;
    width: auto;
    height: 60px;
  }
  .list-addon-home-ui li h4,
  .list-addon-home-ui li p {
    text-align: center;
  }
}
