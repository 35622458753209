.common-heading-section h4 {
  font-size: 30px;
  line-height: 1.3;
  margin: 0;
}
.common-heading-section h3{
  font-size: 34px;
  line-height: 1.2;
  margin-bottom: 15px;
}
.common-heading-section p {
  text-transform: none;
  font-size: 70px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #e3ecf3;
  margin-bottom: -25px;
}
.common-heading-section h5 {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #fff;
  margin: 0px;
  margin-top: 15px;
}
.blacker-sec {
  position: relative;
  margin-bottom: 100px;
}
.blacker-sec:before {
  content: "";
  background-color: #000;
  position: absolute;
  width: 75%;
  z-index: 0;
  height: 100%;
  border-radius: 0px 60px;
  top: 0px;
}

.list-addon-home li h4 {
  font-size: 38px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.26;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.list-addon-home li p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: white;
}

.base-image {
  width: 100%;
  bottom: 0;
  right: 0;
  position: absolute;
  height: 100%;
  object-fit: contain;
  object-position: bottom right;
  visibility: hidden;
}

.base-image {
  top: 0px;
  transition: 0.5s;
  object-position: center;
  visibility: visible;
  margin: auto;
  height: 70%;
}
.base-image:nth-child(1) {
  animation: MoveUpDown 4s linear infinite;
}
.make-leagle ul li h4 {
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: center;
  color: #353536;
  margin: 0px;
  margin-top: 15px;
}
.make-leagle .container1 p {
  font-size: 20px;
  margin-top: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  /* text-align: center; */
  color: #3a3d41;
}

.blacker-sec-text ul {
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  background-image: url(/public/assets/arrow-shape-path.png);
  background-repeat: no-repeat;
  margin-left: -60px;
  margin-right: -120px;
  background-position-x: center;
  background-size: contain;
  background-position-y: 160px;
}
.blacker-sec-text ul li {
  width: 20%;
  text-align: center;
  position: relative;
  padding: 15px;
}
.blacker-sec-text ul li:nth-child(1) {
  margin-top: -100px;
}
.blacker-sec-text ul li:nth-child(2) {
  margin-top: 250px;
  top: -130px;
}
.blacker-sec-text ul li:nth-child(3) {
  margin-top: 100px;
}
.blacker-sec-text ul li:nth-child(4) {
  margin-top: -165px;
}
.blacker-sec-text ul li:nth-child(5) {
  margin-top: -55px;
}
.blacker-sec-text ul li span img {
  width: auto;
  height: 30px;
}
.blacker-sec-text ul li h4 {
  font-size: 120px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.42;
  letter-spacing: normal;
  text-align: center;
  color: #1b1b1b;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  margin-top: -20px;
  opacity: 0.5;
}
.blacker-sec-text ul li h5 {
  font-size: 22px;
  /* font-weight: 900; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0px;
  position: relative;
}
.blacker-sec-text ul li span {
  background-color: #ff850e;
  width: 80px;
  height: 80px;
  padding: 20px;
  border-radius: 50%;
  margin: 40px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.blacker-image figure h2 {
  font-size: 50px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  position: absolute;
  margin: 0px;
  top: 60px;
  left: 45px;
}
.blacker-image figure h3 {
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  font-size: 40px;
  margin: 0px;
  position: absolute;
  bottom: 110px;
  right: -30px;
  transform: rotate(269deg);
  text-transform: uppercase;
}
.blacker-image figure small {
  width: 24px;
  height: 80%;
  border-radius: 10px;
  border: solid 2.5px #ffffff;
  background-color: #fff;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.blacker-image figure small:before {
  content: "";
  width: 100%;
  border-radius: 4.5px;
  background-image: linear-gradient(to top, #ff4f02, #ffb400),
    linear-gradient(to bottom, #ff850e, #ff850e);
  position: absolute;
  height: 90%;
  bottom: 0px;
  transition: 1.5s;
}

.relationship-sec-text h5 {
  font-family: "SF-Pro-Bold";
  font-size: 80px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: left;
  color: #ecf2f7;
  margin-bottom: -30px;
  text-transform: capitalize;
  margin-top: 10px;
}
.relationship-sec-text h4 {
  font-family: "SF-Pro-Bold";
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131d2b;
  margin: 0px;
  margin-bottom: 17px;
  margin-top: 15px;
}
.relationship-sec-text h3 {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #414d5c;
  margin: 0px;
  margin-top: 10px;
}
.relationship-sec-text p {
  font-size: 15px;
  font-family: "SF-Pro-Regular";
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #3a3d41;
  background-image: linear-gradient(to right, #e3ecf3, #fdfefe);
  padding: 23px;
  border-radius: 0px 0px 0px 10px;
}
.relationship-sec-text ul {
  list-style: none;
  margin: 10px 0px;
  padding: 0px;
  margin-bottom: 25px;
}

.relationship-sec-text li {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #3a3d41;
  margin: 15px 0px;
  position: relative;
  padding-left: 40px;
}

.slightly-changes-choose li {
  font-size: 20px;
  font-family: "SF-Pro-Semibold";
  font-stretch: normal;
  font-style: italic;
  line-height: 1.3;
  letter-spacing: normal;
  color: #414d5c;
  text-align: left;
  margin: 40px 0;
  padding: 6px 0px;
  padding-left: 60px;
}
.slightly-changes-choose li:before {
  background-image: url(https://cdn-wp.code-brew.com/wp-content/uploads/2019/11/17220171@3x.png);
  width: 40px;
  height: 40px;
  background-size: contain;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  top: 0px;
  bottom: 0px;
  margin: auto;
}
.long-neck-arrow {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #5c626b;
  cursor: pointer;
  transition: 0.5s;
}
.relationship-sec-img figure {
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  right: -120px;
}
.relationship-sec-img figure img {
  width: 270px;
  border-radius: 10px;
  filter: drop-shadow;
  filter: drop-shadow(0 0 3.1px rgba(0, 0, 0, 0.1));
}
.relationship-sec-img figure img:nth-child(1) {
  margin-bottom: -10px;
}
.relationship-sec-img figure img:nth-child(2) {
  filter: drop-shadow(0 0 13.1px rgba(0, 0, 0, 0.1));
  position: relative;
  z-index: 1;
  backface-visibility: hidden;
  width: 300px;
}
.relationship-sec-img figure img:nth-child(3) {
  margin-top: -10px;
}

.setting-newly-sem.relationship-sec-img figure {
  right: inherit;
  left: -120px;
}
.adding-compare-sec {
  box-shadow: 4.5px 2.1px 13.5px 0 rgba(0, 0, 0, 0.33);
  background-image: linear-gradient(to top, #151e40, #040819);
  padding: 50px 70px 30px 100px;
  margin-top: 150px;
  margin-left: 33.33%;
  border-radius: 0px 50px;
}
.adding-compare-sec figcaption {
  position: absolute;
  left: -330px;
  top: -130px;
}
.adding-compare-sec figcaption figure {
  box-shadow: 5.9px 2.7px 36.5px 0 rgba(29, 37, 46, 0.29);
  background-image: linear-gradient(to top, #002fff, #4c6dff);
  width: 400px;
  text-align: center;
  padding: 85px 50px;
  border-radius: 50%;
  position: relative;
}
.adding-compare-sec figcaption figure:before {
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 33px solid #0837ff;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 61px;
  margin: auto;
  width: 30px;
  height: 30px;
  transform: rotate(32deg);
}
.adding-compare-sec figcaption figure h3 {
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.76);
  margin: 0px;
  margin-bottom: 30px;
}
.adding-compare-sec figcaption figure strong {
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: block;
}
.adding-compare-sec figcaption figure small {
  font-size: 14px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #b9c6ff;
}
.adding-compare-sec figcaption img {
  width: 70px;
  border-radius: 50%;
  border: 1px solid #d8d9da;
}

.adding-compare-sec h5 {
  display: flex;
  justify-content: center;
  position: relative;
}
.adding-compare-sec h5 span:nth-child(1):before {
  content: "+";
  font-size: 40px;
  position: absolute;
  font-family: sans-serif;
  color: #fff;
  /* left: 180px; */
  right: -12px;
  bottom: 30px;
  font-weight: 300;
}
.adding-compare-sec h5 span:nth-child(2):before {
  content: "⚌";
  font-size: 40px;
  position: absolute;
  font-family: sans-serif;
  color: #fff;
  /* left: 180px; */
  right: -12px;
  bottom: 30px;
  font-weight: 300;
}
.adding-compare-sec h5 span {
  position: relative;
  padding: 0px 40px;
}
.adding-compare-sec h5 span i {
  display: block;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  margin: auto;
}
.adding-compare-sec h5 span i img {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.adding-compare-sec h5 span:nth-child(3) b {
  font-size: 22px;
  line-height: 1.3;
}
.adding-compare-sec h5 span b {
  font-size: 34px;
  font-weight: 900;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.51;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: block;
  margin: 30px 0px;
}
.adding-compare-sec p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-top: 20px;
  padding-left: 40px;
  position: relative;
}

.marketing-lists {
  padding: 0px;
  margin: 0px;
  list-style: none;
  margin-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
}
.newly-choose-mar {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.newly-choose-mar li {
  cursor: pointer;
  float: left;
  width: calc(50% - 30px);
  margin: 15px;
  box-shadow: 5.4px 2.5px 19.6px 0.4px rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  padding: 25px;
  border-radius: 20px 0px 20px 0px;
  position: relative;
  transition: 0.5s;
  background-image: linear-gradient(to top, #fff, #fff);
}
.newly-choose-mar li:hover {
  transition: 0.5s;
  background-image: linear-gradient(to top, #3052e9, #001c98);
}
.newly-choose-mar li img {
  height: 60px;
  transition: 0.5s;
  width: auto;
}
.newly-choose-mar li:hover img {
  filter: brightness(0) invert(1);
}
.newly-choose-mar li:hover h4,
.newly-choose-mar li:hover p {
  color: #fff;
}

.newly-choose-mar li h4 {
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #131d2b;
  margin: 15px 0px;
  margin-bottom: 10px;
  transition: 0.5s;
}
.newly-choose-mar li p {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #414d5c;
  transition: 0.5s;
}
.newly-choose-mar li:nth-child(1),
.newly-choose-mar li:nth-child(3) {
  top: 30px;
}

span.trusted {
  background-image: linear-gradient(to top, #1b2448, #040819);
  font-size: 24px;
  font-weight: 900;
  display: flex;
  align-items: center;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding: 63px 46px;
  border-radius: 20px 0px 20px 0px;
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: 180px;
  left: 0px;
}
.client-logo-cta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 3.2px 1.5px 15.7px 0.3px rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  border-radius: 20px 0px 20px 0px;
  padding: 80px 20px;
  justify-content: flex-end;
  margin-top: 30px;
}
.client-logo-cta img {
  width: 23%;
  height: 150px;
  object-fit: scale-down;
  padding: 30px 10px;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust the distance you want to move */
  }
}

/* Add styles for fade-in animation */
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(
      50px
    ); /* Adjust the distance and direction of fade-in */
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 991px) {
  .base-image {
    width: 90%;
  }
  .relationship-sec-text {
    margin-top: 15px;
    order: 2 !important;
  }
  .mate-module {
    position: static;
    width: 100%;
    max-width: 500px;
    height: auto;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .mate-module img {
    padding-top: 110px;
    position: static;
  }
  .common-heading-section h4 {
    font-size: 26px;
  }
  .relationship-sec-text h5,
  .relationship-sec-text h4,
  .relationship-sec-text p {
    text-align: center;
  }
  .relationship-sec-text h5 {
    font-size: 20px;
    color: #ff850f;
    margin-bottom: 15px;
    margin-top: 30px;
  }
  .relationship-sec-text ul.slightly-changes-choose {
    margin-top: 20px;
  }
  .relationship-sec-text li {
    text-align: center;
    padding-left: 0px;
    margin: 10px 0;
  }
  .newly-choose-mar li h4 {
    font-size: 16px;
  }
  .newly-choose-mar li p {
    font-size: 14px;
  }
  .newly-choose-mar li {
    float: left;
    width: calc(50% - 15px);
    margin: 7px;
    padding: 15px;
  }
  span.trusted {
    position: static;
    display: block;
    height: auto;
    padding: 15px;
    border-radius: 0px;
    text-align: center;
    margin: 40px 0px;
    margin-bottom: 20px;
  }
  .client-logo-cta {
    width: 100%;
    box-shadow: none;
    padding: 15px;
    justify-content: center;
    margin-top: 0px;
  }
  .client-logo-cta img {
    width: 50% !important;
    height: 100px;
    object-fit: scale-down;
    padding: 15px 7.5px;
  }
  .rated-now-sec li a {
    display: block !important;
    padding: 20px 15px !important;
  }
  .rated-now-sec li img {
    height: 50px !important;
    filter: none !important;
    width: auto;
  }
  .rated-now-sec li h4 {
    font-size: 16px !important;
    margin-top: 15px !important;
  }
  .relationship-sec-img img {
    max-width: 200px;
    width: 100%;
  }
  .relationship-sec-text h4 {
    font-size: 22px;
    line-height: 1.1;
  }
  .long-neck-arrow {
    font-size: 14px;
    text-align: center !important;
  }
  .relationship-sec-img figure {
    display: none !important;
  }
  .long-neck-arrow {
    font-size: 14px;
    justify-content: center;
    text-align: center !important;
  }
  .relationship-sec-img {
    order: 1 !important;
  }
  .adding-compare-sec {
    margin: 0 !important;
    padding: 50px 15px;
    display: flex;
    flex-direction: column;
  }
  .adding-compare-sec figcaption {
    position: static;
    max-width: 500px;
    width: 100%;
    margin-top: 40px!important;
    margin: auto;
    transform: scale(1);
    order: 5;
  }
  .adding-compare-sec figcaption figure {
    width: 100%;
    padding: 30px;
    border-radius: 20px;
  }
  .adding-compare-sec figcaption figure:before {
    border-top: 33px solid #0531ff;
    bottom: -22px;
    left: 0px;
    transform: rotate(0deg);
    right: 0px;
  }
  .adding-compare-sec figcaption {
    position: static;
    max-width: 500px;
    width: 100%;
    margin: auto;
    transform: scale(1);
    order: 5;
  }
  .adding-compare-sec figcaption img {
    margin: 50px auto 0 auto;
  }
  .adding-compare-sec h5 {
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 0px;
  }
  .adding-compare-sec h5 span {
    padding: 0px;
    width: 100%;
    margin: 15px 0px;
  }
  .adding-compare-sec h5 span:nth-child(1):before {
    right: 50%;
    bottom: -30px;
    transform: translateX(50%);
  }
  .adding-compare-sec h5 span:nth-child(2):before {
    right: 50%;
    bottom: -30px;
    transform: translateX(50%);
  }
  .adding-compare-sec p {
    text-align: center;
    margin-top: 20px;
    padding-left: 0px;
  }
  .marketing-lists li figcaption h4 {
    margin: 0;
    font-size: 20px !important;
  }
  .marketing-lists li figcaption h5 {
    font-size: 20px !important;
  }

  .blacker-sec {
    position: relative;
    background-color: #000;
    margin-bottom: 0px;
  }
  .blacker-sec-text ul {
    background-image: none;
    margin: 0px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .blacker-sec-text ul li {
    width: 50%;
    text-align: center;
    position: static;
    margin: 0px 0 !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .blacker-sec-text ul li h5 {
    order: 2;
    font-size: 20px;
    font-weight: 600;
  }
  .blacker-sec-text ul li span {
    order: 1;
    margin: 0px auto;
    margin-bottom: 20px;
  }
  .blacker-sec-text ul li h4 {
    display: none;
  }
  .blacker-image figure h2 {
    font-size: 30px;
  }
  .blacker-image figure h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 1200px) {
  .list-addon-home {
    margin-top: 0px !important;
    margin-bottom: 45px !important;
  }
  .list-addon-home li {
    border: solid 1px #4a4a4a !important;
    background-color: transparent;
    border-radius: 0 !important;
    margin: 0;
    padding: 20px 15px;
    width: 50% !important;
  }
  .list-addon-home li img {
    object-fit: scale-down;
    object-position: center left;
    width: auto;
    height: 50px;
    margin: auto;
    margin-bottom: 20px;
    display: block;
    position: static;
  }
  .list-addon-home li h4 {
    text-align: center;
  }
  .list-addon-home li p {
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  .relationship-sec-text li:before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: #fffefe;
    left: 0;
    top: 8px;
    margin: auto;
    border: 3px solid #000;
    border-radius: 50%;
  }
  .adding-compare-sec p:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    left: 5px;
    border-radius: 50%;
    top: 10px;
  }
}
