

.star-rated {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.over-rated li {
    border-radius: 5px;
    border: 0.5px solid #e4ebf0;
    background-color: #f7faff;
    text-align: center;
    /* margin-top: 30px; */
    margin-bottom: 0;
    padding: 30px 15px;
    background-image: none;
    transition: .5s;
}

ul.star-rated li {
    width:calc(25% - 30px);
    /* background-color: #fff; */
    /* background-image: url(https://cdn-gnpmp.nitrocdn.com/zXfatNvCxdJLOJQwdBcjdAFpyWHclmaD/assets/images/optimized/rev-f369670/www.code-brew.com/wp-content/uploads/2019/07/vector-smart-object-copy-6@2x.png); */
    background-size: 171px;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 10px;
    /* padding: 30px 25px; */
    margin: 15px 15px 15px;
    /* border-radius: 8px; */
}


@media screen and (min-width:992px) {
    .over-rated li p {
        text-align: center;
        display: flex;
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 999;
        bottom: 0;
        background-color: #009e70;
        padding: 15px;
        align-items: center;
        margin: 0;
        top: 100%;
        transition: .5s;
        opacity: 0;
    }
    .over-rated li:hover p {
        top: 0;
        opacity: 1;
    }
    body .over-rated li:hover {
        background-color: #f7faff;
    }
    .over-rated li {
        position: relative;
        overflow: hidden;
        background-color: #eef4f2;
    }
    .over-rated li:hover p {
        color: #fff;
    }
}

@media screen and (max-width:600px) {
    ul.star-rated li {
        width: calc(100% - 30px) !important;
        
    }
}
@media screen and (max-width:991px) {
    ul.star-rated li {
        width: calc(50% - 30px);
        margin-top: 15px;
        margin-bottom: 0 !important;
    }
}