

/* Basic styles for the Navbar */
.navbar {
  background-color: #3498db;
  color: white;
  padding: 10px 0;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-toggler {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: white;
  padding: 5px;
}

/* Navbar links and dropdown */
.navbar-collapse {
  display: block;
  padding: 10px 0;
}

.navbar-collapse.show {
  display: block;
}

.navbar-nav {
  list-style: none;
  display: flex;
}

.nav-item {
  margin-right: 20px;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1rem;
}

/* Navbar toggler icon */
.navbar-toggler-icon {
  border: 2px solid white;
  border-radius: 4px;
  padding: 2px;
  display: none; /* Initially hidden */
}

.navbar-toggler-icon::before {
  content: '';
  display: block;
  width: 15px;
  height: 2px;
  background-color: white;
  margin-bottom: 3px;
}

.navbar-toggler-icon::after {
  content: '';
  display: block;
  width: 15px;
  height: 2px;
  background-color: white;
  margin-top: 3px;
}

/* Media Query for screens wider than 900px */
@media (min-width: 901px) {
  .navbar-toggler {
    display: none; /* Hide the toggle button */
  }

  .navbar {
    background-color: #3498db;
  }

  .navbar-collapse {
    display: flex !important; /* Ensure it's always visible on larger screens */
  }

  .navbar-nav {
    margin-right: 20px;
  }

  .nav-item {
    margin-right: 20px;
  }

  .navbar-toggler-icon {
    display: none;
  }
}
