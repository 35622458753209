.top-banner-side-image {
  position: absolute;
  width: 710px;
  right: inherit;
  left: 45%;
  top: 0px;
  bottom: 0px;
  margin: auto;
  padding: 0px;
  height: 500px;
}
.branchesList li:hover span {
  border: 2px solid #deb21e;
}
.dotted-track {
  width: 100%;
  left: 0px;
  right: -70px;
  top: -50px;
  bottom: 0px;
  margin: auto;
  position: absolute;
}
.top-banner-side-image:before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: #deb21d;
  top: 0px;
  bottom: -300px;
  margin: auto;
  border-radius: 50%;
  left: 100px;
  animation: movers 10s infinite forwards;
  animation-delay: 7s;
}
.top-banner-side-image .main-phone {
  position: absolute;
  width: 580px;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  animation: move 6s infinite forwards;
}
.block-one-phone {
  top: 20px;
  bottom: 0px;
  margin: auto;
}
.block-two-phone {
  top: 270px;
  bottom: 0px;
  margin: auto;
  left: -70px;
}
.block-three-phone {
  bottom: -80px;
  margin: auto;
  left: 80px;
  right: 0px;
}
.block-four-phone {
  right: 0px;
  bottom: 0px;
  top: 150px;
  margin: auto;
}
.cbCommonBlock {
  position: absolute;
  width: 180px;
  /* visibility: hidden; */
}

.cbCommonBlock img {
  width: 100%;
}
.myCarouselMob {
  width: 200px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  transition: 0.5s;
}
.first-inner-carousel {
  position: relative;
  transition: 0.5s;
  padding: 22px;
}
.hidden-squize {
  left: 50% !important;
  margin-left: -100px;
}
.first-inner-carousel img {
  width: 100%;
  transform: scale(1.1);
  transition: 0.5s;
}
.first-inner-carousel:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(/public/assets/chakra.webp);
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
}

.sectionFour {
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  background-color: #000;
}
.sectionFour {
  background-image: url(https://studio.code-brew.com/wp-content/uploads/2020/07/group-63@3x-1.jpg);
}

.animation-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.animate {
  background-color: lightblue;
  transform: scale(1.2);
  transition: all 0.5s;
}
.cb-sub-heading-large {
  font-size: 32px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.29px;
  margin: 0px;
}
.cb-sub-heading-middle {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #dab700; */
}
.cb-sub-heading-small {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.-small {
  /* font-size: 19px; */
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0px;
}
.fp-section.fp-table,
.fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fp-section {
  padding-top: 80px;
}
.fp-section {
  display: block !important;
  height: auto !important;
}
.first-inner-carousel.fadder {
  top: 500px !important;
  transform: scale(1.8);
}
.branchesList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  margin: 0px;
  margin-top: 70px;
  margin-bottom: 70px;
}
.branchesList li {
  width: 50%;
  position: relative;
}
.branchesList li:nth-child(even) span {
  float: right;
}

@media screen and (min-width: 992px) {
  .branchesList li:before {
    content: "";
    background-image: url(/public/assets/branch.webp);
    position: absolute;
    width: 200px;
    height: 70px;
    background-size: contain;
    right: -100px;
    background-repeat: no-repeat;
    z-index: 1;
    /* opacity: 0; */
    transition: 0.5s;
    transition-delay: 1s;
  }
  .branchesList li:nth-child(1):before {
    top: 81px;
    bottom: 0px;
    margin: auto;
    right: 114px;
    transform: rotate(-5deg);
  }
  .branchesList li:nth-child(2):before {
    right: inherit;
    left: 118px;
    transform: rotate(-35deg);
    top: 87px;
  }
  .branchesList li:nth-child(3):before {
    top: 60px;
    right: 120px;
  }
  .branchesList li:nth-child(4):before {
    right: inherit;
    left: 112px;
    top: 60px;
    transform: rotate(-25deg);
  }
  .branchesList li:nth-child(5):before {
    top: 30px;
    transform: rotate(-28deg);
    right: 110px;
  }
  .branchesList li:nth-child(6):before {
    right: inherit;
    left: 112px;
    top: 30px;
  }
}

.branchesList li span {
  display: block;
  font-family: "SF-Pro-Bold";
  text-align: left;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: #000000;
  padding: 25px 15px;
  padding-left: 75px;
  position: relative;
  width: 260px;
  border-radius: 6px;
  box-shadow: -20px 22px 54px 0 rgba(0, 0, 0, 0.22);
  background-color: var(--white);
  margin: 15px 0px;
  border: 2px solid transparent;
}
.branchesList li img {
  position: absolute;
  width: 50px;
  left: 15px;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.posTaken h4 {
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f2f2f2;
  margin: 0;
}
.posTaken ul {
  margin: 20px 0px;
}
.posTaken ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 0; */
  overflow: hidden;
}
.posTaken ul li {
  width: calc(20% - 30px);
  margin: 15px;
  padding-bottom: 0px;
  border-radius: 0px 10px 0px 0px;
}
.posTaken ul li {
  background-image: linear-gradient(to bottom, #eeeeee, #d8d8d8);
  position: relative;
}
.posTaken ul li:nth-child(4),
.posTaken ul li:nth-child(5),
.posTaken ul li:nth-child(3),
.posTaken ul li:nth-child(2) {
  background-image: linear-gradient(to top, #b68b00, #dcb900) !important;
}
.posTaken ul li p {
  margin: 0px;
  padding: 30px 15px;
  border-radius: 10px;
}
.posTaken ul li:nth-child(4) p,
.posTaken ul li:nth-child(3) p,
.posTaken ul li:nth-child(2) p,
.posTaken ul li:nth-child(5) p {
  color: white;
}
.posTaken ul li p {
  font-family: "SF-Pro-Semibold";
  font-size: 16.2px;
  font-weight: 700;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e2e2e;
  padding-bottom: 25px;
  text-transform: capitalize;
}
.underProjectTicketCta {
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
}
.underProjectTicketCta p {
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0px;
  margin-right: 0px;
  line-height: 1.5;
  width: 60%;
  padding-right: 15px;
}
.cb-steps {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 50px -12.5px;
}
.cb-steps li {
  width: calc(16.66% - 25px);
  margin: 12.5px;
  text-align: center;
  border-radius: 3px;
}
.cb-steps li a {
  display: block;
  background-color: #131313;
  padding: 12px 12px 0;
  overflow: hidden;
  position: relative;
  transition: 0.4s;
  top: 0;
  border-radius: 5px;
  padding-bottom: 60px;
  backface-visibility: hidden;
  height: 100%;
}
.cb-steps li a i {
  opacity: 0.06;
  font-size: 122px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  position: absolute;
  right: -15px;
  top: -15px;
}
.cb-steps li h5 {
  font-size: 17.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  margin-bottom: 50px;
}
.cb-steps li h4 {
  font-size: 20.5px;
  /* font-weight: 700; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: -.73px; */
  text-align: center;
  color: #fff;
}
.cb-steps li p {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin: 15px 0;
  margin-bottom: 0;
}
.cb-steps li img {
  width: 40px;
  margin-bottom: 0;
  transition: 0.4s;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -60px;
}
.cb-steps li:hover a {
  top: 0;
  transform: scale(1.1);
}
.cb-steps li:hover img {
  bottom: 10px !important;
}
.next-genlist {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -15px;
}
.next-genlist li {
  width: calc(33.33% - 30px);
  margin: 15px;
  /* background-image: linear-gradient(100deg,#2a303a 3%,#000000 94%); */
  text-align: center;
  padding: 25px 15px;
  /* padding-top: 25px; */
  transition: 0.4s;
}
.next-genlist li i {
  width: 46px;
  height: 46px;
  background-color: #dab700;
  display: block;
  border-radius: 50%;
  position: relative;
  margin: auto;
  margin-bottom: 30px;
}
.next-genlist li i img {
  position: relative;
  top: 10px;
  left: -10px;
}
.next-genlist li h4 {
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 8px 0;
}
.next-genlist li h4,
.next-genlist li p {
  color: #101010;
}
.next-genlist li p {
  font-size: 18px;
  font-family: "SF-Pro-Regular";
  /* font-weight: 500; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}
.extraLineAddedd {
  opacity: 0.81;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.36;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
  display: block;
  margin-top: 20px;
}
.sectionEight .next-genlist li p,
.sectionEight .next-genlist li h4,
.sectionEight .nexygen-text,
.sectionEight .nexygen-text p {
  text-align: left !important;
}
.rewamp-arrow-animate a {
  opacity: 0.76;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-top: 31px;
  display: inline-block;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}
.cb-left-right-text a:after {
  content: "➞";
  font: normal normal normal 24px/1 FontAwesome;
  margin-left: 15px;
  vertical-align: middle;
  color: #daaf1c;
  animation: mymove 2s;
  animation-iteration-count: infinite;
  position: relative;
}
.cb-left-right-text a {
  cursor: pointer;
  transition: 0.4s;
}
.cb-left-right-text a:hover {
  color: #daaf1c;
}
.cb-left-right-text a:hover:after {
  color: #fff;
}
.accord-text .card {
  position: relative;
  padding-left: 50px;
  border: solid 1px rgba(255, 255, 255, 0.11);
  background-color: rgba(37, 42, 51, 0.37);
  margin: 15px 0;
  border-radius: 0;
}
.accord-text .card img {
  position: absolute;
  left: 18px;
  top: 18px;
  height: 30px;
  width: 30px;
  object-fit: scale-down;
}
.accord-text .card-body {
  opacity: 0.8;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  padding-top: 5px;
}

.industry-list-cb {
  position: static;
  display: flex;
  margin: 0 -15px;
  list-style: none;
}
.industry-list-cb li {
  flex: 3;
  margin: 0 15px;
  margin-top: 30px;
  position: relative;
}
.industry-list-cb li > img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.industry-list-cb li figcaption {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  padding: 15px;
  left: 0;
  right: 0;
  overflow: hidden;
  transition: 0.4s;
  display: block;
  z-index: 1;
}
.industry-list-cb li:hover figcaption {
  background-color: #d4a70e;
}
.btn-register-common {
  display: inline-block;

  border: solid 1px #deb21d;
  background-color: #deb21d;
  padding: 15px 20px 15px 47px;
  position: relative;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff !important;
  border-radius: 3px;
  transition: 0.4s;
  cursor: pointer;
}
.industry-list-cb li figcaption h4 {
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.86px;
  color: #fff;
  margin: 0;
  margin-top: 15px;
}
.industry-list-cb li figcaption img {
  width: 50px;
  display: block;
}
.industry-list-cb li:hover figcaption a {
  right: 0;
}
.industry-list-cb li figcaption a {
  font-size: 12px;
  font-family: "SF-Pro-Regular";
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 50px;
  position: absolute;
  right: -200px;
  bottom: 10px;
  transform: scale(0.8);
  transform-origin: center;
  background-color: #000;
  transition: 0.4s;
}
.industry-list-cb:nth-of-type(odd) li:nth-child(3) {
  flex: 6;
}
.industry-list-cb:nth-of-type(even) li:nth-child(1) {
  flex: 6;
}
.btn-register-common:before {
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  animation: pulso 2s infinite;
  transition: 0.5s;
  content: "";
  width: 15px;
  height: 15px;
  border: 3px solid #fff;
  position: absolute;
  left: 15px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  animation: pulso 2s infinite;
}
.analnyticsText span {
  height: 148px;
  border: solid 1px rgba(255, 255, 255, 0.47);
  background-image: linear-gradient(100deg, #1a242f 3%, #010102 94%);
  opacity: 0.8;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 15px;
  margin: 30px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.analnyticsText span {
  justify-content: center;
}
.statical-text ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.statical-text ul li {
  width: 50%;
  padding: 30px 0;
  padding-right: 100px;
}
.statical-text-simple .ticker-points li:before {
  background-color: #e6e6e6;
}
@media screen and (min-width: 992px) {
  .ticker-points li:before {
    content: "";
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: #000;
    border: 3px solid #fff;
    border-radius: 50%;
    top: 5px;
  }
}
.statical-text ul li img {
  height: 45px;
  margin-bottom: 10px;
  width: 45px;
  object-fit: scale-down;
}
.statical-text ul li h4 {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 25px;
  color: #fff;
}
.statical-text ul li h5 {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #bebebe;
  margin: 0;
}
ul.ticker-points li {
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #fff;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
}
.statical-text-simple .cta-bottom {
  background-image: linear-gradient(to left, #b68b00, #dcb900);
  margin: 0;
  padding: 30px;
  margin-top: 50px;
}
.statical-text ul li h4 i {
  font-size: 45px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: #fff;
}
.statical-text-simple .cta-bottom p {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #fff;
  margin-bottom: 25px;
  margin-top: 10px;
}
.statical-text-simple .cta-bottom h4 {
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: -0.93px;
  color: #fff;
}
.statical-text-simple .cb-sub-heading-large {
  font-size: 28px;
  line-height: 1.3;
}
#site-footer {
  text-align: center;
  background-color: transparent;
  margin-bottom: 0;
  margin-top: 80px;
  border: none !important;
  padding: 20px;
}
#site-footer a {
  display: block;
  width: 150px;
  margin: auto;
}
#site-footer a img {
  width: 100%;
}
#site-footer a p {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #565656;
  margin: 0;
  margin-top: 10px;
}

.sectionSeven {
  background-image: linear-gradient(45deg, #fff8ef 2%, #ffffff 98%);
}

@media screen and (min-width: 992px) {
  .sectionEight .next-genlist li,
  .sectionTen .next-genlist li {
    padding-left: 80px !important;
    margin-top: 2rem !important;
    position: relative;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .settingingUp li {
    width: 100% !important;
  }
  .sectionEight .next-genlist li i,
  .sectionTen .next-genlist li i {
    margin: 0px;
    margin-bottom: 25px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .top-banner-side-image {
    width: 665px;
  }
}
@media screen and (max-width: 991px) {
  .top-banner-side-image {
    position: relative;
    width: 300px;
    height: 300px;
    left: 0;
    margin-bottom: 30px;
    margin-top: 60px;
  }
  .top-banner-side-image img {
    position: absolute;
    width: 80px;
  }
  .top-banner-side-image:before,
  .dotted-track {
    display: none;
  }
  .top-banner-side-image .main-phone {
    position: absolute;
    width: 100%;
  }
  .top-banner-side-image .main-phone {
    position: absolute;
    animation: move 6s infinite forwards;
  }
  .block-one-phone {
    top: 20px;
    bottom: 0;
    margin: auto;
  }
  .block-two-phone {
    top: 210px;
    bottom: 0;
    margin: auto;
    left: 0;
  }
  .block-three-phone {
    bottom: 75px;
    margin: auto;
    left: 130px;
    right: 0;
  }
  .block-four-phone {
    right: -110px;
    bottom: 0;
    top: 140px;
    margin: auto;
  }
  .myCarouselMob {
    position: static !important;
    margin: 0px auto;
    transform: scale(1);
  }
  .first-inner-carousel.fadder,
  .myCarouselMob {
    position: static !important;
    margin: 0px auto;
    transform: scale(1);
  }
  .cb-sub-heading-large {
    font-size: 24px !important;
    text-align: center !important;
  }
  .branchesList {
    margin-left: -7.5px;
    margin-top: 30px;
    margin-bottom: 40px;
    margin-right: -7.5px;
  }
  .branchesList li {
    width: calc(50% - 15px);
    position: relative;
    margin: 7.5px;
  }
  .branchesList li span {
    font-size: 14px;
    padding: 15px;
    text-align: center;
  }
  .branchesList li span {
    width: 100%;
    margin: 0px;
  }
  .branchesList li img {
    position: static;
    width: 30px;
    display: block;
    margin: auto;
    margin-bottom: 10px;
  }
  .sectionFour {
    background-image: none;
  }
  .posTaken ul li {
    width: calc(33.33% - 15px);
    margin: 7.5px;
  }
  .underProjectTicketCta {
    display: block;
    text-align: center;
  }
  .underProjectTicketCta p {
    width: 100%;
    padding: 0px;
    margin-bottom: 25px;
  }
  .cb-steps {
    margin: 20px -12.5px;
  }
  .cb-steps li {
    width: 240px;
  }
  .cb-steps li h5 {
    margin-bottom: 20px;
  }
  .next-genlist {
    justify-content: center;
  }
  .next-genlist li {
    width: calc(50% - 15px);
    margin: 7.5px;
  }
  .cb-sub-heading-middle {
    text-align: center;
  }
  .sectionSeven .next-genlist li p,
  .sectionSeven .next-genlist li h4,
  .sectionSeven .nexygen-text,
  .sectionSeven .nexygen-text p {
    text-align: center !important;
  }
  .industry-list-cb {
    flex-wrap: wrap;
    justify-content: center;
  }
  .industry-list-cb li {
    max-width: 250px;
    flex: 100% !important;
    width: 100%;
  }
  .analnyticsText span {
    height: auto;
    width: 50%;
    margin: 0;
  }
  .statical-text ul {
    margin-bottom: 30px;
  }
  .statical-text ul li {
    padding-right: 30px;
    padding: 15px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center !important;
  }
  .statical-text ul li h4 i {
    font-size: 25px;
  }
  .statical-text-simple .cb-sub-heading-large {
    font-size: 24px !important;
  }
  ul.ticker-points {
    list-style: none;
    padding: 0;
    margin: 25px 0;
    margin-top: 15px;
  }
  ul.ticker-points li {
    text-align: center;
    padding: 0;
    font-weight: 400;
    font-size: 18px;
  }
  .cta-bottom {
    text-align: center;

    padding: 35px;
    background-size: cover;
    background-position: center;
    margin-top: 40px;
  }
  .analnyticsText {
    max-width: 100%;
    flex: 100%;
    order: 2;
    display: flex;
    flex-wrap: wrap;
  }
  .sectionThirteen .inner-pot-slider h4 {
    font-size: 24px;
  }
  .sectionThirteen .inner-pot-slider ul {
    flex-wrap: nowrap !important;
    justify-content: center;
  }
  .sectionThirteen .inner-pot-slider img {
    width: 40px;
    margin: auto;
    display: block;
    margin-bottom: 10px;
  }
  .inner-pot-slider ul li big {
    display: block;
    font-size: 16px !important;
  }
  .inner-pot-slider ul li small {
    display: block;
    font-size: 12px !important;
  }
  .inner-pot-slider-img img {
    max-width: initial;
    width: auto !important;
    margin: auto;
    margin-top: 10px;
    height: 400px;
  }
}

@media screen and (max-width: 768px) {
  .cb-steps li img {
    bottom: 15px;
    width: 30px;
  }
  .next-genlist li {
    width: calc(100% - 30px);
    margin: 15px;
  }
  .industry-list-cb li {
    max-width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  /* .branchesList {
    margin-left: -30px;
    margin-right: -30px;
  } */
  .branchesList li:before {
    width: 140px;
  }
}

#menu.show {
  top: 77px;
  border-top: 1px solid #464646;
}

#menu {
  width: 100%;
  position: fixed;
  z-index: 9;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #000;
  top: -100px;
  transition: 0.4s;
}
#menu li a {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #c3c3c3;
  padding: 15px;
  display: block;
  text-align: center;
}
#menu li a b {
  display: block;
  margin-bottom: 5px;
  font-weight: 300;
}
