/* .home-banner.webDevelopment-banner {
  background-image: none;
  position: relative;
  overflow: hidden;
} */

/* @media screen and (min-width:991px) {
  .home-banner.webDevelopment-banner{
    background-image: linear-gradient(56deg, #131946, #353a63);
    width: 50%;
    top:9vh;
    height: 91vh;
    position: absolute;
    background-size: cover;
    background-position: center right;
    left: 0;
    border-radius: 0 0 100px 0;
  }
} */

.custom-web-service-shadow {
  box-shadow: 1px -1px 20px 0 rgba(179, 179, 179, 0.26);
}

.diagonal-line-bg{
  background-image: url(/public/assets/diagonal-line-bg.png);
  background-size: cover;
}
.filter-bright{
  filter: brightness(100) !important;
}
.ServiceCardWeb{
  border-radius: 0.5rem !important;
}
.ServiceCardWeb:hover span{
  color: white;
}
.ServiceCardWeb:hover img{
  filter: brightness(100);
}

/* Animated background */
.animated-gradient-background {
  background: linear-gradient(300deg,rgb(82, 189, 134),rgb(211, 134, 0),rgb(255, 162, 0));
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.carousel-control-prev {
  width: 5% !important;
}
.carousel-control-next {
  width: 5% !important;
}
.goal-item {
  position: relative;
  cursor: pointer;
  font-size: 24px;
  padding: 15px 0;
  font-weight: 400;
  color: #484646;
  border-bottom: 1px solid #444444;
  transition: 0.3s;
}
.goal-item-active {
  color: white !important;
}
.goal-item-active::after {
}
.web-development-banner {
  /* overflow: hidden; */
}

.Animatedcard1 .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #2c73df; */
}
.Animatedcard1 .icon .Animatedcard1-cover-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.Animatedcard1 .side {
  width: 280px;

  height: 200px;

  transition: 0.5s;
}
.heart-logo-glow{
  transition: 0.3s;
}
.heart-logo-glow:hover {
  transition: 0.3s;
  text-shadow: 0 0 3px #ffe205, 0 0 7px #ffe205, 0 0 20px #edd205;
  color: #ffe205;
  /* background-color: #262626; */
}

.Animatedcard1 .side.frontSide {
  position: relative;

  display: flex;

  justify-content: center;

  align-items: center;

  z-index: 1;

  transition: 0.7s;

  transform: translateY(100px);
}

.Animatedcard1:hover .side.frontSide {
  transform: translateY(0px);
}

.Animatedcard1 .side.backSide {
  position: relative;

  display: flex;

  justify-content: center;

  align-items: center;

  padding: 20px;

  box-sizing: border-box;

  transition: 0.8s;

  transform: translateY(-100px);

  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.Animatedcard1:hover .side.backSide {
  transform: translateY(0);
}

.Animatedcard1 .side.backSide::after {
  content: "";

  position: absolute;

  width: 30px;

  height: 4px;

  bottom: 15px;

  left: 50%;

  left: 50%;

  transform: translateX(-50%);

  background: #2c73df;
}

.Animatedcard1 .side.backSide .content p {
  margin: 0;

  padding: 0;

  text-align: center;

  color: #414141;
}

.Animatedcard1 .side.backSide .content h3 {
  margin: 0 0 10px 0;

  padding: 0;

  font-size: 24px;

  text-align: center;

  color: #414141;
}

.web-development-banner {
  background-image: none;
  position: relative;
  background-color: #f5f7fe;
}

.home-banner {
  background-size: cover;
  background-position: center right;
}
@media screen and (max-width: 990px) {
  .home-banner.web-development-banner {
    padding: 150px 15px 50px 15px;
    background-image: linear-gradient(56deg, #131946, #353a63);
  }
}

@media screen and (min-width: 991px) {
  .home-banner.web-development-banner::before {
    content: "";
    position: absolute;
    background-image: linear-gradient(56deg, #131946, #353a63);
    width: 50%;
    z-index: 0;
    top: 9vh;
    height: 91vh;
    left: 0;
    border-radius: 0 0 100px 0;
  }
  .webServiceBanner::before {
    content: "";
    position: absolute;
    background-image: linear-gradient(56deg, #131946, #353a63);
    width: 55%;
    z-index: 0;
    height: 100%;
    right: 0;
    border-radius: 100px 0 100px 0;
  }
}

