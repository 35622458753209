.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Hide both scrollbar thumb and track */
/* For WebKit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px !important;
  height: 0 !important;
}

/* For Firefox */
/* scrollbar-width: none; */

/* For Internet Explorer */
/* -ms-overflow-style: none; */

/* Style the scrollbar */
body::-webkit-scrollbar {
  width: 5px;
}

/* Track (the background of the scrollbar) */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Thumb (the draggable part of the scrollbar) */
body::-webkit-scrollbar-thumb {
  background: #888;
}

/* On hover, style the thumb */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For menulist of navbar */
.menuList::-webkit-scrollbar {
  width: 5px;
}

/* Track (the background of the scrollbar) */
.menuList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Thumb (the draggable part of the scrollbar) */
.menuList::-webkit-scrollbar-thumb {
  background: #888;
}

/* On hover, style the thumb */
.menuList::-webkit-scrollbar-thumb:hover {
  background: #555;
}