.review-card-bg {
  background-image: url(/public/assets/card-bg.webp);
  background-size: 171px;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 10px;
}

.client-testimonials .item {
  border-radius: 5px;
  box-shadow: 0 4.5px 12.5px 0 rgba(17, 25, 109, 0.15);
  background-color: #fff;
  padding: 30px;
  min-height: 360px;
}
.client-testimonials .item h4 {
  margin: 0;
  position: relative;
  padding: 15px;
  padding-left: 70px;
  padding-right: 0;
}

.client-testimonials .item h4 img {
  position: absolute;
  width: 50px;
  left: 0;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
}
.client-testimonials .item h4 b {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131d2b;
  display: block;
  margin-bottom: 5px;
}
.client-testimonials .item h4 span {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d60;
}
.client-testimonials .item h5 {
  margin: 0;
  margin-top: 20px;
}