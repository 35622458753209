.contactUsBtn {
  background-image: url(https://www.ibrandox.com/assets/images/medidrp/buttnclnt.png);
  background-size: cover;
  border-bottom: none;
  padding: 3px 60px;
  text-transform: uppercase;
  border: none;
  text-align: center;
  color: #fff;
  height: 40px;
  line-height: 3;
  width: auto;
  display: flex;
  align-items: center;
}
.inquiry-gradient {
  background-image: linear-gradient(to right, #fdf08d, #ffe428);
  /* Add any other properties you need */
}

.inquiry-form .inputfield .form-control {
  background-color: transparent !important;
}

.inquiry-form .react-tel-input input{
    background-color: transparent !important;
}
.inquiry-form .react-tel-input .form-control{
    background-color: transparent !important;
    border-bottom: none !important;
}
.inquiry-form textarea {
    background-color: transparent !important;
  }

