.transitionCard1 {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-left: -15px;
    margin-right: -15px;
}
.transitionCard1 li {
    width: calc(25% - 30px);
    float: left;
    margin: 15px;
    text-align: center;
    background-color: #f19f27;
    border-radius: 5px;
    padding: 40px 15px;
    position: relative;
    overflow: hidden;
}
.transitionCard1 li {
    background-color: #8cc541;
}
.transitionCard1 li img {
    height: 40px;
    filter: brightness(0) invert(1);
}
.transitionCard1 li h3 {
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin: 0;
    margin-top: 20px;
}
.transitionCard1 li p {
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    left: 0;
    right: 0;
    bottom: -110%;
    top: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    transition: .5s;
}
.transitionCard1 li:hover p {
    top: 0;
}

@media screen and (max-width:991px) {
    .transitionCard1 li {
        width: calc(50% - 15px);
        float: left;
        margin: 7.5px;
        padding: 25px 15px;
    }
}
@media screen and (max-width:600px) {
    .transitionCard1 li {
        width: calc(100% - 15px) !important;
    }
}