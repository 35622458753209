.flip-container {
  background: transparent;
  display: inline-block;
}

.flip-this {
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-container:hover .flip-this {
  transition: 0.9s;
  transform: rotateY(360deg);
}

.partnerTestimonial {
  position: absolute;
  width: 75px;
  height: 75px;
  border: none;
  box-shadow: 0 0 0 0 rgba(61, 132, 232, 0.7);
  border-radius: 50%;
  background-color: #1366ff;
  /* background-image: url(YOUR-URL-IMAGE.png); */
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-animation: pulse 1.7s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.7s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.7s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.7s infinite cubic-bezier(0.66, 0, 0, 1);
}
.partnerTestimonial:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(61, 104, 232, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(61, 104, 232, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(61, 104, 232, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(61, 104, 232, 0);
  }
}

.countup-text {
  font-size: 3.9rem;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -3px;
  color: #232323;
}
.number-suffix {
  color: #e6994e;
  font-size: 30px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 3px;
  vertical-align: top;
}
.review-heading {
  margin-left: -20px;
  font-size: 18px;
  margin-bottom: 15px;
  /* font-weight: 400; */
  line-height: 20px;
  letter-spacing: 2px;
}
.review-subtext {
  margin-left: -20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
}
.parallax-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.parallax-image {
  position: absolute;
  background-size: contain;
  background-position: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* transition: transform 0.3s ease-in-out; */
}

.transition-transform {
  transition: 0.4s;
}
.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place particles behind the content */
}

.animated-subtitle h2 {
  color: #232323;
  display: block;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  z-index: 1;
}
.animated-subtitle {
  cursor: pointer;
  width: fit-content;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: black;
  text-decoration: none;
}

.animated-subtitle::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #888888;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-subtitle span {
  position: absolute;
}

.animated-subtitle span {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(8, 20, 43, 0)),
    to(#1d1d1d)
  );
  background: linear-gradient(to right, rgba(8, 20, 43, 0), #1d1d1d);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@-webkit-keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

/* Button with rippling circle */
.ripple-effect-button {
  transition-delay: 1.2s;
  position: relative;
}

.ripple-effect-button::before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  border: 3px solid #fff;
  border-radius: 50%;
  left: 15px;
  transition: 0.5s;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.571);
  animation: ripple 2s infinite;
}

@-webkit-keyframes ripple {
  to {
    box-shadow: 0 0 0 22px rgba(255, 255, 255, 0);
  }
}
@-moz-keyframes ripple {
  to {
    box-shadow: 0 0 0 22px rgba(255, 255, 255, 0);
  }
}
@-ms-keyframes ripple {
  to {
    box-shadow: 0 0 0 22px rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  to {
    box-shadow: 0 0 0 22px rgba(255, 255, 255, 0);
  }
}

.zoom-in-component {
  animation: zoomin 0.5s ease; /* Use the "zoomin" animation */
  visibility: hidden; /* Initially hidden */
}
.zoom-in-component.zoomActive {
  opacity: 1;
  transform: scale(1); /* Final scale (normal size) */
}

@keyframes zoomIn {
  from {
    transform: scale(0.8); /* Initial scale down */
    opacity: 0;
  }
  to {
    transform: scale(1); /* Final scale (normal size) */
    opacity: 1;
  }
}
@keyframes move-right-to-left {
  0% {
    left: 10px;
  }
  50% {
    left: 20px;
  }
  100% {
    left: 10px;
  }
}
/* Hide the image initially */
.fade-in-right-image {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1s, transform 1s;
}

/* When the image is in the viewport, fade it in and move it back to the original position */
.fade-in-right-image.visible {
  opacity: 1;
  transform: translateX(0);
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes zoomIn1 {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes movers {
  0% {
    left: 100px;
    bottom: -300px;
  }
  7% {
    left: 198px;
    bottom: -165px;
  }
  17% {
    left: 33px;
    bottom: 45px;
  }
  35% {
    left: 416px;
    bottom: 480px;
  }
  45% {
    left: 732px;
    bottom: 140px;
  }
  55% {
    left: 732px;
    bottom: 140px;
  }
  65% {
    left: 590px;
    bottom: -34px;
  }
  70% {
    left: 590px;
    bottom: -147px;
  }
  80% {
    left: 390px;
    bottom: -371px;
  }
  90% {
    left: 205px;
    bottom: -166px;
  }
  100% {
    left: 95px;
    bottom: -305px;
  }
}

@keyframes move {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(25px);
  }
  75% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes pulse-custom {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    -ms-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes slide-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-80px) translateX(-80px);
    transform: translateY(-80px) translateX(-80px);
  }
}

@keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(80px) translateX(80px);
    transform: translateY(80px) translateX(80px);
  }
}
