.swiper-container {
  width: 500px;
}

.swiper-pagination {
  bottom: 0;
  padding-bottom: 10px;
}

.slick-slide{
  min-height: 100%;
  height: 100% !important;
}

.swiper-wrapper {
  padding-inline-start: 0;
}
.slick-next {
  top: 95% !important;
  right: 7% !important;
}
.slick-prev {
  left: 5% !important;
  top: 95% !important;
  z-index: 1;
}
.slick-prev::before {
  content: "← Prev" !important;
  white-space: nowrap;
  cursor: pointer;
  font-family: "GalanoSemiBold";
}
/* .slick-prev{
  top: 95%!important;
  left: 7% !important;
} */
.slick-next::before {
  content: "Next →";
  white-space: nowrap;
  cursor: pointer;
  font-family: "GalanoSemiBold";
}

@media screen and (max-width: 991px) {
  .slick-next {
    bottom: 35px !important;
    top: auto !important;
    right: 15% !important;
  }
  .slick-prev {
    left: 5% !important;
    bottom: 35px !important;
    top: auto !important;
    z-index: 1;
  }
}
/* .slick-prev::before{
  content:"← Prev";
  transition: 0.3s;
  white-space: nowrap;
  cursor: pointer;
  font-family: 'GalanoSemiBold';
} */
.slick-next::before:hover {
  background-color: #0000004d;
}
/* .slick-prev::before:hover{
  background-color: #0000004d;
} */
.crausal-text h3 {
  font-family: "SF-Pro-Bold";
  font-size: 34px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  /* text-align: left; */
  color: #ffffff;
  border-bottom: 1px solid #404884;
  padding-bottom: 50px;
  margin-bottom: 50px;
  margin-top: 0px;
}
.crausal-text p {
  /* font-size: 22px; */
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  /* margin: 40px 0; */
  position: relative;
  /* opacity: 0; */
  /* top: 50px; */
  transition: 0.5s;
}
.crausal-text p span {
  color: rgba(255, 255, 255, 0.8);
}
.crausal-text ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 25px; */
  /* margin-bottom: 70px; */
  margin-left: -30px;
  margin-right: -30px;
  /* opacity: 0; */
  /* top: 50px; */
  position: relative;
  transition: 0.5s;
}
.crausal-text ul li:nth-child(2) {
  border-left: 1px solid #fff;
  /* border-right: 1px solid #fff; */
  padding-left: 55px;
  /* text-align: left; */
}
.crausal-text ul {
  opacity: 1;
  /* top: 0; */
  transition-delay: 1s;
}
.crausal-text ul li {
  width: 33.333%;
  text-align: left;
  padding: 0 30px;
}
@media screen and (max-width: 991px) {
  .crausal-text h3 {
    font-size: 24px !important;
    margin-bottom: 30px !important;
    padding-bottom: 30px !important;
  }
  .crausal-text ul li {
    text-align: center;
    padding: 0 15px !important;
  }
  .crausal-text ul li big {
    font-size: 24px !important;
  }
  .align-content-text{
    text-align: center ;
  }

}
.align-content-text{
  text-align: justify ;
}

.crausal-text ul li big {
  display: block;
  font-size: 45px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.26;
  letter-spacing: normal;
  /* text-align: left; */
  color: #fff;
}
.crausal-text ul li small {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.25;
  letter-spacing: normal;
  /* text-align: left; */
  color: rgba(255, 255, 255, 0.8);
}
.slider-2 {
  background-image: linear-gradient(56deg, #508207, #007870) !important;
}
.slider-3 {
  background-image: linear-gradient(56deg, #ff3459, #cb1737) !important;
}
.red-gradient {
  background-image: linear-gradient(56deg, #ce3a2f, #ae291f);
}
.slider-4 {
  background-image: linear-gradient(56deg, #365f8c, #5ba6d7);
}
.purple-gradient {
  background: linear-gradient(56deg, #7b5f8c, #a65bd7);
}
.yellow-gradient {
  background: linear-gradient(56deg, #f5d537, #ffec82);
}
.gray-gradient {
  background: linear-gradient(56deg, #333333, #666666);
}
.green-gradient {
  background-image: linear-gradient(56deg, #508207, #007870);
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.webCarousel-2 .slick-list {
  width: 65%;
  margin-left: auto;
}
/* .sd.webCarousel-2 .slick-list{
  display: flex;
} */

.webCarousel-2 .slick-dots {
  display: flex !important;
  width: fit-content;
  gap: 4rem;
  flex-direction: column !important;
  bottom: 55%;
  left: 0%;
  /* right: 30%; */
  transform: translateY(50%);
}

.webCarousel-2 .slick-dots li .carousel-2-logo {
  position: relative !important;
  height: 50px;
  width: 50px;
  z-index: 3;
  padding: 10px;
  border-radius: 50%;
}
.webCarousel-2 .slick-dots li .carousel-2-logo::before {
  content: "";
  transition: 0.5s !important;
  position: absolute;
  height: 60px;
  width: 60px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  border: 2px solid #bfc4cd;
  /* background-color: #383838; */
}
.active-slide::before {
  border: 2px solid #8cc541 !important;
  background-color: #8cc541;
}
.sd .active-slide::before {
  border: 2px solid #f19f27 !important;
  background-color: #f19f27;
}
.inactiveSlide::before {
  background-color: white;
  /* border: 2px solid #5b5b5b; */
}

.webCarousel-2 .slick-dots li:not(:last-child) .carousel-2-logo::after {
  content: "" !important;
  z-index: -1 !important;
  height: 100% !important;
  position: absolute !important;
  bottom: -60% !important;
  width: 3px !important;
  background-color: #bfc4cd;
  transition: 0.5s !important;
}
.active-slide::after {
  background-color: #8cc541 !important;
}
.sd .active-slide::after {
  background-color: #f19f27 !important;
}

.progress-bar-container {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ccc;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease-in-out;
}
.custom-dot {
  width: 30px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
}

.custom-dot.active {
  border: 2px solid #007bff;
}
.sd.webCarousel-2 .slick-slider {
  display: flex;
  align-items: center;
}
.sd.webCarousel-2 .slick-list {
  width: 65%;
  margin-right: auto;
  margin-left: 0;
}
.sd.webCarousel-2 .slick-dots {
  background-color: #1c1d25;
  height: 32rem;
  position: relative;
  display: flex !important;
  padding: 50px 50px;
  border-radius: 15px;

  /* position: relative; */
  /* height: 100%; */
  bottom: 0;
  width: 32%;
  gap: 4rem;
  flex-direction: column !important;
  transform: translateY(0);
}

@media screen and (max-width: 991px) {
  .webCarousel-2 .slick-list {
    width: 80% !important;
    margin-left: auto;
  }
  .webCarousel-2 .slick-dots {
    gap: 2.5rem;
    /* bottom: 65%; */
  }
  .webCarousel-2 .slick-dots li .carousel-2-logo::before {
    height: 50px;
    width: 50px;
  }
}
@media screen and (max-width: 650px) {
  .webCarousel-2 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .slider-message {
    margin: 0 !important;
  }
}


/* Animation */

.img1 {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.5s, transform 0.5s;
}

.img1.visible {
  opacity: 1;
  transform: translateY(0);
}