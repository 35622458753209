.button4 {
    border: none;
    padding: 8px 0px;
    width: 10rem;
    /* font-size: 44px; */
    position: relative;
    /* background: white; */
    color: black;
    /* text-transform: uppercase; */
    border: 1px solid black;
    cursor: pointer;
    transition: all 0.7s;
    overflow: hidden;
    /* border-radius: 5px; */
  }

  .button4:hover {
    border: 1px solid black;
    color: white;
  }
  .button4 span {
    transition: all 0.7s;
    z-index: -1;
  }

  .button4 .first {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    width: 25%;
    height: 100%;
    background: #191919;
  }

  .button4:hover .first {
    top: 0;
    right: 0;
  }
  .button4 .second {
    content: "";
    position: absolute;
    left: 25%;
    top: -100%;
    height: 100%;
    width: 25%;
    background: #191919;
  }

  .button4:hover .second {
    top: 0;
    left: 50%;
  }

  .button4 .third {
    content: "";
    position: absolute;
    left: 50%;
    height: 100%;
    top: 100%;
    width: 25%;
    background: #191919;
  }

  .button4:hover .third {
    top: 0;
    left: 25%;
  }

  .button4 .fourth {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 25%;
    background: #191919;
  }

  .button4:hover .fourth {
    top: 0;
    left: 0;
  }