@media screen and (min-width: 992px) {
  .blockchain-grid::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 92px;
    opacity: 0.44;
    border-style: solid;
    border-width: 1px;
    border-image-source: radial-gradient(
      circle at 0 50%,
      #fff,
      rgba(255, 255, 255, 0)
    );
    border-image-slice: 1;
    right: 0;
  }
}

.adjust-spacing-tabs {
  border-radius: 17px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.09);
  background-image: linear-gradient(to right, #2967cd, #29b2cd);
  text-align: center;
  padding: 30px;
  margin-top: -9vh;
  position: relative;
  margin-bottom: 80px;
}
.blockchain-accordion {
  padding: 5px 0 5px 30px;
}
.blockchain-grid-flex-3 {
  flex: 3;
}
.blockchain-grid-flex-6 {
  flex: 6;
}

.lottie-class {
  transform: translate3d(0px, 0px, 0px);
  content-visibility: visible;
}

.blockchain-grid-2 figcaption {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  bottom: -9rem;
  padding: 21px;
  left: 0px;
  right: 0px;
  /* top: unset; */
  overflow: hidden;
  display: block;
  z-index: 1;
}
.blockchain-grid-2 figcaption h4 {
  font-size: 20px;
  font-weight: 700;
  height: 64px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: -0.86px; */
  color: #ffffff;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  position: relative;
}
.blockchain-grid-2 figcaption h4::before {
  z-index: 1;
  content: "";
  position: absolute;
  width: 6px;
  height: 100%;
  background: #2257af;
  left: -20px;
  top: -7px;
}
.blockchain-grid-2:hover figcaption h4::before {
  background: white !important;
}

.ProcessStates li {
  width: 20%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 35px;
  position: relative;
  padding-bottom: 180px;
  overflow: hidden;
  transition: 0.1cs;
  border-right: solid 1px rgba(151, 151, 151, 0.18);
}
.activeProcessState {
  background-image: linear-gradient(
    242deg,
    #45a3e9 100%,
    #2378ce 12%
  ) !important;
  color: black !important;
}
.ProcessStates li sup {
  opacity: 0.64;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.21) 1%,
    rgba(255, 255, 255, 0.09)
  );
  font-size: 148px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  top: 0px;
  margin-bottom: -60px;
  display: block;
}
.ProcessStates li h4 {
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  /* color: #fff; */
}

.ProcessStates li p {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  /* color: #fff; */
  margin: 0px;
  padding: 20px 0px;
  padding-left: 30px;
  position: relative;
}
@media screen and (min-width: 992px) {
  .ProcessStates li p:before {
    width: 15px;
    height: 15px;
    border: 3px solid;
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    border-radius: 50%;
  }
}

/* Blockchain Tiles */

.blockchain-tiles-layout ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
  /* padding-left: 20px; */
  align-items: center;
}
.blockchain-tiles-layout ul li:nth-child(1),
.blockchain-tiles-layout ul li:nth-child(3) {
  margin-top: 80px;
}
.blockchain-tiles-layout ul li {
  width: 25%;
}
.blockchain-tiles-layout ul li span.wow {
  opacity: 1;
  transition: 0.4s;
  cursor: pointer;
}
.blockchain-tiles-layout ul li span.wow:hover {
  background-image: linear-gradient(263deg, #45a3e9 100%, #2378ce 1%);
  cursor: pointer;
}
.blockchain-tiles-layout ul li span.wow:hover img {
  filter: brightness(0) invert(1);
}
.blockchain-tiles-layout ul li span.wow:hover h4 {
  color: #fff;
}
.blockchain-tiles-layout ul li span {
  background-image: linear-gradient(to bottom, #ececec 0%, #f9f9f9 98%),
    linear-gradient(to bottom, #ededed, #eeeeee);
  display: flex;
  height: 165px;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
}
.blockchain-tiles-layout ul li span img {
  height: 50px;
  margin: 0px;
  display: block;
  margin-bottom: 10px;
}
.blockchain-tiles-layout ul li span h4 {
  font-size: 15px;
  font-family: "SF-Pro-Semibold";
  /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #262c36;
  text-transform: uppercase;
}
.blockchain-grid-wrap {
  display: flex;
}

@media screen and (max-width: 992px) {
  .css-o4b71y-MuiAccordionSummary-content{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .blockchain-faq .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin-bottom: 0px !important;
  }
  .css-15v22id-MuiAccordionDetails-root {
    padding-top: 0 !important;
  }
  .blockchain-grid-flex-3,
  .blockchain-grid-flex-6 {
    flex: none !important;
  }
  .blockchain-grid-2 {
    max-width: unset !important;
  }
  .blockchain-grid-wrap {
    display: block;
    margin: auto;
    max-width: 500px;
  }
  .blockchain-grid-2 figcaption {
    position: static;
    padding: 0;
  }
  .blockchain-grid-2 figcaption h4 {
    font-size: 20px;
    margin-top: 1rem;
    height: auto;
    padding: 0;
    color: #000;
  }
  .blockchain-tiles-layout {
    margin-top: 30px;
    text-align: center;
    max-width: 100%;
    width: 100%;
    flex: 100%;
  }
  .blockchain-tiles-layout ul {
    display: inline-block;
  }
  .blockchain-tiles-layout ul li {
    width: 100% !important;
    display: inline-block;
  }
  .blockchain-tiles-layout ul li:nth-child(1),
  .blockchain-tiles-layout ul li:nth-child(3) {
    margin-top: 80px;
  }
  .blockchain-tiles-layout ul li {
    width: 33.33%;
  }
  .blockchain-tiles-layout ul li {
    margin: 0px !important;
  }
  .blockchain-tiles-layout ul li span.wow {
    display: inline-flex !important;
    width: 50%;
  }
  .blockchain-tiles-layout ul li span {
    display: none;
    height: 125px;
  }
  .blockchain-tiles-layout ul li span img {
    height: 35px;
  }
  .blockchain-tiles-layout ul li span h4 {
    font-size: 13px;
  }
  .ProcessStates li {
    width: 50%;
  }
  .ProcessStates li:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
  .ProcessStates li {
    width: 50%;
    padding: 15px;
    text-align: center;
  }
  .ProcessStates li sup {
    font-size: 48px;
    margin: 0px;
    margin-bottom: 10px;
  }
  .ProcessStates li sup {
    background-image: linear-gradient(
      to bottom,
      rgb(255 255 255) 1%,
      rgba(255, 255, 255, 0.09)
    );
  }
  .ProcessStates li h4 {
    font-size: 24px;
  }
  .ProcessStates li p {
    padding: 8px 0px;
    font-size: 16px;
  }
  .blockchain-accordion {
    padding: 10px 10px 10px 20px;
  }
  .adjust-spacing-tabs {
    border-radius: 0px;
    margin: 0px auto;
    width: 100%;
    max-width: 100%;
  }
}
