.tag {
  display: inline-block;
  font-family: "SF-Pro-Regular";
  padding: 6px 14px;
  background: #000;
  color: #fff;
  margin-bottom: 1rem;
  border: 1px solid;
}
.section.section--banner {
  background-color: #040404;
  position: relative;
}
.container-cb,
.container-fluid-cb,
.container-lg-cb,
.container-md-cb,
.container-sm-cb,
.container-xl-cb {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.outlined-text {
  text-transform: uppercase;
  font-size: calc(2rem + 4vw);
  line-height: 1;
  font-weight: 900;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgb(0 0 0 / 0%);
  -webkit-text-stroke: 1px #fff;
  opacity: 0.39;
  margin-bottom: -50px;
  display: block;
}
.banner-img-wrapper:before {
  content: "";
  background: url(https://www.code-brew.com/wp-content/themes/Avada-Child-Theme/media/2022/09/about-banner-arrow-shape.png?var=1572658996)
    no-repeat;
  background-size: cover;
  width: 98px;
  height: 61px;
  position: absolute;
  left: 4%;
  bottom: -44px;
}
.banner-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  flex-direction: column;
}
.banner-img-wrapper .img-primary {
  -webkit-animation: slide-tl 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-tl 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 1s;
}
.banner-img-wrapper .img-secondary {
  -webkit-animation: slide-br 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-br 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 1s;
}
.banner-img-wrapper .banner-img {
  position: absolute;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.banner-content p {
  font-size: 1.25rem;
  line-height: 1.7;
  margin-bottom: 2rem;
}
.trusted {
  display: flex;
  align-items: center;
  /* position: absolute; */
  padding-top: 2rem;
  border-top: 1px solid rgb(255 255 255);
  /* bottom: 2rem;
  left: 0;
  right: 0; */
  justify-content: space-between;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
}
.trusted p {
  margin: 0;
  color: #999999;
  font-size: 24px;
  margin-right: 100px;
}
.brand-wrapper {
  display: flex;
  align-items: center;
}

.brand-wrapper .brand-item {
  height: 35px;
  display: flex;
  justify-content: center;
}
.brand-wrapper .brand-item:not(:last-child) {
  margin-right: calc(2rem + 3vw);
}

/* Section 2 */

.panel.panel--counter-primary {
  background-image: url(https://www.code-brew.com/wp-content/themes/Avada-Child-Theme/media/2022/09/about-counter-bg.png?var=1917172068);
  background-size: cover;
  background-position: center;
  background-color: rgb(14 25 3);
  border-radius: 18px;
  background-repeat: no-repeat;
  color: #fff;
  padding: calc(2rem + 1.5vw) calc(2.7rem + 3vw);
  aspect-ratio: 1 / 0.6;
  margin: 0;
}

.panel.panel--counter-primary .panel-title {
  margin-bottom: 2rem;
}
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}
.count-title {
  font-size: 40px;
  font-weight: normal;
}

.panel.panel--counter-primary .panel-content {
  display: flex;
  align-items: center;
}
.panel-content .count:not(:last-child) {
  margin-right: 3rem;
  padding-right: 3rem;
  border-right: 1px solid #979797;
}
.count {
  line-height: 1.3;
}

.counter .type {
  display: flex;
  align-items: center;
}
.counter h2 {
  margin-bottom: 1rem;
}
.counter .type img {
  margin-right: 1rem;
}
.counter .type h5 {
  margin-bottom: 0;
}

/* Section-3 */

.quote {
  flex-direction: column;
}

.mission-wrapper {
  background-color: #0a1000;
  border-radius: 10px;
  color: rgb(255 255 255);
  padding: calc(3rem + 2vw) 0;
  margin-top: 2rem;
  background-image: url(https://www.code-brew.com/wp-content/themes/Avada-Child-Theme/media/2022/09/about-mission-shape.svg?var=632073658);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.quote p {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  font-style: italic;
}
.quote p span {
  opacity: 0.7;
}
.name {
  font-size: 20px;
  font-weight: bold;
  padding: 10px 27px;
  background-color: #70aa25;
  margin-right: 1rem;
  position: relative;
  padding-right: 2rem;
  text-transform: uppercase;
}
.name:before {
  content: "";
  position: absolute;
  border-top: 50px solid rgb(10 16 0);
  border-left: 28px solid rgb(0 0 0 / 0%);
  right: 0;
  top: 0;
}
.designation {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
}

/* section 5 */

.tile.tile--team {
  cursor: pointer;
  background-image: linear-gradient(to bottom, #f8f9f4, #e4e6e0);
  padding: 3rem 2.5rem;
  border-radius: 10px;
  border: 1px solid #e5e7e1;
  transition: all 0.3s;
}
.tile.tile--team:hover {
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.21);
}
.tile.tile--team .tile-body > h2 {
  color: #d9ddd0;
  text-transform: uppercase;
  /* font-size: 40px; */
}
.team-counter {
  margin-bottom: 1.5rem;
}
.team-counter h2 {
  margin-bottom: 0.5rem;
}
.team-counter p {
  font-size: 20px;
}
.team-group {
  display: inline-flex;
  position: relative;
}
.team-group:before {
  content: "+";
  position: absolute;
  height: 50px;
  width: 50px;
  background: #70aa25;
  border-radius: 50%;
  border: 2px solid rgb(255 255 255);
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 37px;
  color: #fff;
  line-height: 1.4;
  right: 0;
}
.team-group .team-img {
  height: 50px;
  width: 50px;
}
.team-group .team-img:not(:first-child) {
  margin-left: -22px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

.card.card--reasons {
  border: 1px solid #e4e4e4;
  border-radius: 0;
  padding: calc(2rem + 2vw) calc(1.4rem + 1vw);
  background-color: #fff;
  box-shadow: 0 12px 74px 0 rgba(0, 0, 0, 0.14);
  transition: all 0.3s;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1 / 1.2;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.col-xl-3,
.col-md-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .trusted {
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .brand-item {
    max-width: calc(50% - 30px) !important;
    flex: 0 0 calc(50% - 30px) !important;
    flex-basis: calc(50% - 30px) !important;
  }
  .panel-content .count h2 {
    font-size: 22px;
  }
  .card.card--reasons {
    aspect-ratio: unset;
    padding: 1rem 1.5rem 2rem;
    height: 100%;
  }
}
@media screen and (min-width: 768px) {
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .quote-icn {
    position: absolute;
    top: -112px;
    left: -40px;
  }
  .quote {
    flex-direction: row !important;
  }
  .quote-content {
    width: 55%;
  }
  .quote-img-cover {
    width: 40%;
  }
  .banner-content h1 {
    font-size: 45px;
  }
  .counter-wrapper [class*="col"]:not(:last-child) {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #d2d2d2;
  }
  .mission-wrapper .img-cover {
    margin-top: -13rem;
  }
  .card.card--reasons:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: #0a1000;
    left: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  .card.card--reasons .card-head {
    z-index: 1;
  }
  .card.card--reasons .card-head .icn {
    height: 122px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
    transition: all 0.3s;
  }
  .card.card--reasons .card-body {
    padding: 2rem calc(1.4rem + 1vw) calc(1.4rem + 1vw);
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  
  .card.card--reasons:hover {
    color: #fff;
  }
  .card.card--reasons:hover:before {
    top: 0;
    opacity: 1;
    visibility: visible;
  }
  .card.card--reasons:hover .card-body {
    opacity: 1;
    visibility: visible;
    bottom: 0;
    top: unset;
  }
  .card.card--reasons:hover .card-head .icn {
    opacity: 0;
    visibility: hidden;
    height: 0;
    margin: 0;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 1400px) {
  .banner-img-wrapper {
    aspect-ratio: 2 / 1.3;
  }
  .outlined-text {
    font-size: calc(4rem + 4vw);
  }
  .container--narrow {
    width: 100%;
    max-width: 1360px;
  }
  .outlined-text {
    font-size: calc(4rem + 4vw);
  }
  .banner-content h1 {
    font-size: 55px;
  }

  .card.card--reasons {
    aspect-ratio: 1 / 1.49;
  }
  .card .card-body p {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 767px) {
  .section--banner {
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  .section--banner {
    height: 100%;
    padding: 180px 0 20px !important;
  }
  .banner-content h1 {
    font-size: 30px;
    line-height: 1.3;
  }
  .section--banner,
  .mobile-common,
  .section-banner {
    padding-top: 100px !important;
  }
  .section {
    padding: 40px 0;
  }
  .trusted {
    position: static;
    flex-direction: column;
  }
  .trusted p {
    font-size: 20px;
    margin-right: unset;
    margin-bottom: 1rem;
  }
  .brand-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  .brand-wrapper .brand-item img {
    object-fit: contain;
  }
  .brand-item {
    max-width: calc(20% - 30px);
    flex: 0 0 calc(20% - 30px);
    margin: 15px !important;
  }
  .quote p {
    font-size: 20px;
  }
  .quote-icn {
    margin-bottom: 1rem;
  }
  .name {
    font-size: 14px;
    margin-right: 1rem;
    padding: 10px 15px;
    padding-right: 15px;
  }
  .designation {
    font-size: 14px;
  }
  .count-title {
    font-size: 24px;
  }
  .panel-content .count:not(:last-child) {
    margin-right: 2rem;
    padding-right: 1rem;
    border-right: 1px solid #fff;
  }
  .quote-icn img {
    height: 40px;
  }
  .quote p {
    font-size: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .text-align {
    text-align: center;
  }
  .center-align {
    margin: auto;
    text-align: center;
  }
  .banner-img-wrapper {
    height: 300px;
    aspect-ratio: 2 / 1.3;
  }
  .banner-img-wrapper .banner-img {
    width: 250px;
    bottom: 0;
  }
  .section--banner {
    padding: 180px 0 180px;
  }
  .outlined-text {
    margin-bottom: -25px;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  /* Section2 */

  .panel.panel--counter-primary .panel-content {
    justify-content: center;
  }
  .quote-content {
    order: 2;
  }
  .quote-img-cover {
    order: 1;
  }
  .quote {
    text-align: center;
    padding: 0 20px;
  }
  .quote-icn img,
  .section--OurMission .img-cover img {
    margin: 0 auto;
  }
  .d-flex.align-items-center {
    justify-content: center;
    margin-bottom: 50px;
  }
  .img-cover img {
    margin: 0 auto;
  }
  .card.card--reasons {
    aspect-ratio: auto;
    padding: 1.5rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .banner-img-wrapper {
    height: 350px;
  }
  .mission-wrapper {
    margin-top: 8rem;
  }

  .quote p {
    font-size: 30px;
  }
  .card.card--reasons {
    aspect-ratio: 1 / 1.3;
  }
  .card.card--reasons {
    padding: 1rem;
  }
  .card.card--reasons .card-body {
    padding: 1rem !important;
}
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner-img-wrapper .banner-img {
    width: 300px;
    bottom: 0;
  }
  /* .trusted {
    bottom: -15rem;
  } */
}
@media screen and (min-width: 1200px) and (max-width: 2499px) {
  .section--banner {
    display: flex;
    align-items: center;
  }
  .outlined-text {
    margin-bottom: -28px;
  }
}

@media screen and (max-width: 1399px) {
  .section--banner {
    height: 100%;
    padding: 180px 0 220px;
  }
  .banner-img-wrapper {
    aspect-ratio: 2 / 1.3;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px) {
  .section--banner {
    height: 100%;
    padding: 180px 0 220px;
  }
  .outlined-text {
    margin-bottom: -28px;
  }
  .trusted {
    bottom: 3rem;
  }
}
@media screen and (min-width: 1600px) {
  .mission-wrapper .img-cover {
    margin-top: -22.5rem;
  }
  .banner-img-wrapper:before {
    left: 14%;
    bottom: 0;
  }
  .container--narrow {
    max-width: 1720px;
  }
  .section--banner {
    height: 100vh;
    padding: 0 50px;
  }
  .outlined-text {
    font-size: calc(4rem + 5vw);
  }
  .banner-content h1 {
    font-size: 4.375rem;
    line-height: 1.14;
  }
  .card.card--reasons {
    aspect-ratio: 1 / 1.2;
  }
}
