.footer-widget-common h4 {
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;

    color: #1b1b1b;
    margin: 0;
    margin-bottom: 20px;
}
.footer-widget-common p {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #141414;
    margin-top: 18px;
    margin-bottom: 0;
}
.footer-widget-common p a:hover {
    /* text-decoration: underline; */
    color: #70aa26;
}
.footer-widget-common p a {
    color: #1c1c1c;
    font-family: "SF-Pro-Regular";
}
.commenFoterSection {
    background-color: #f7f7f7;
    padding: 15px 25px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
}
.commenFoterSection small {
    display: block;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 10px;
    float: left;
    width: 100%;
}
.forSocialIconsOnly {
    position: relative;
    align-items: center;
    margin-top: 20px;
    float: left;
    width: 100%;
}

.foote-copyrights {
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
    padding: 15px 0px;
    margin-top: 40px;
}

@media screen and (max-width:991px) {
    .foote-copyrights{
        margin-top: 0 !important;
    }
    /* .commenFoterSection{
        margin-bottom: 0 !important;
    } */
}