@media screen and (min-width: 991px) {
  .home-banner .row {
    height: 100vh !important;
  }
}

.animated-module {
  position: absolute;
  width: 60%;
  height: 100vh;
  right: 0;
}
.sd-base-image {
  width: 100%;
  bottom: 0;
  right: 0;
  position: absolute;
  height: 100%;
  object-fit: contain;
  object-position: bottom right;
  visibility: visible;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.animated-module span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}
.animated-module span:nth-of-type(1) {
  transform: translate(9%, 0%);
  animation: leftRight 8s linear infinite;
}
.animated-module span:nth-of-type(2) {
  transform: translate(-24%, -20%);
  animation: slideUpDown 3s linear infinite;
}
.animated-module span:nth-of-type(3) {
  transform: translate(8%, -19%);
  animation: slideUpDown 3s linear infinite;
}
.animated-module span:nth-of-type(4) {
  transform: translate(42%, -14%);
  animation: slideUpDown 2s linear infinite;
}
.animated-module span:nth-of-type(5) {
  transform: translate(-43%, 15%);
  animation: slideUpDown 2.5s linear infinite;
}
.animated-module span:nth-of-type(6) {
  transform: translate(18%, 21%);
  animation: slideUpDown 3s linear infinite;
}
.sd-banner {
  background-image: linear-gradient(311deg, #1e2caa 0, #121273);
  position: relative;
  overflow: hidden;
}

.home-banner {
  /* background-image: url(https://www.code-brew.com/wp-content/uploads/2019/08/banner-2-1-1.jpg); */
  background-size: cover;
  background-position: center right;
}

/* Section 2 Diagonal Tile */

.dignol-tile {
  background-image: url(https://www.code-brew.com/wp-content/uploads/2019/09/rectangle-1945-copy-11@3x.png);
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  padding-bottom: 150px;
}

.title-common {
  text-align: center;
  padding: 85px 70px !important;
  position: relative;
  z-index: 1;
  height: 292px;
}

.title-common img {
  height: 40px;
  margin: 0 auto;
}
.title-common h4 {
  font-family: "SF-Pro-Semibold";
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin: 0;
  margin-top: 20px;
  color: #fff;
}
.sepical-dignol-titile-big {
  position: absolute;
  width: 400px;
  height: 400px;
  left: -5px;
  right: 0;
  margin: auto;
  bottom: -110px;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sepical-dignol-titile-small {
  position: absolute;
  width: 250px;
  height: 250px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 59px 30px;
  top: 33px;
}
.rotate-tile-1 .title-common:nth-child(1) {
  left: 12.8%;
}
.rotate-tile-1 .title-common:nth-child(2) {
  left: 37.7%;
}
.rotate-tile-2 .title-common {
  margin-top: -140px;
}
.rotate-tile-3 .title-common {
  margin-top: -140px;
}
.rotate-tile-3 .title-common:nth-child(1) {
  left: 12.2%;
}
.rotate-tile-3 .title-common:nth-child(2) {
  left: 37.7%;
}
@media screen and (min-width: 1201px) {
  .sepical-dignol-titile-big:before,
  .sepical-dignol-titile-small:before,
  .rotate-tile-1 .title-common:nth-child(2):before,
  .rotate-tile-3 .title-common:nth-child(2):before,
  .rotate-tile-2 .title-common:nth-child(2):before,
  .rotate-tile-2 .title-common:nth-child(1):before {
    background-image: linear-gradient(to top, rgba(30, 44, 170, 1) 0, #1c2dc2) !important;
  }
  .title-common:before {
    content: "";
    position: absolute;
    background-image: linear-gradient(to top, rgba(221, 139, 16, 1) 0, #d78915);
    width: 64%;
    height: 64%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    transform: rotate(134deg);
    z-index: -1;
    margin: auto;
  }
}

.rewamp-common-heading {
  position: relative;
  padding-top: 1.5rem;
}
.rewamp-common-heading:before {
  content: "";
  width: 60px;
  height: 3px;
  background-color: #fbb747;
  position: absolute;
  top: 0;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes leftRight {
  0%,
  100% {
    left: 0;
    bottom: 0;
  }
  50% {
    left: -240px;
    bottom: -130px;
  }
}
@keyframes slideUpDown {
  0%,
  100% {
    top: 0;
  }

  50% {
    top: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .dignol-tile {
    padding: 60px 0;
    padding-bottom: 0;
  }
  .sepical-dignol-titile-big,
  .sepical-dignol-titile-small {
    position: static;
  }
  .rotate-tile-1 .title-common:nth-child(1){
    background-color: #f19f27 !important;
  }
  .rotate-tile-2 .title-common:nth-child(1) {
    background-color: #253288 !important;
  }
  .rotate-tile-2 .title-common:nth-child(2) {
    background-color: #f19f27 !important;
  }
  .rotate-tile-2 .title-common:nth-child(3) {
    background-color: #f19f27 !important;
  }
  .rotate-tile-2 .title-common:nth-child(4) {
    background-color: #253288 !important;
  }
  .rotate-tile-3 .title-common:nth-child(2){
    background-color: #f19f27 !important;
  }

  .rotate-tile-1,
  .rotate-tile-2,
  .rotate-tile-3 {
    margin: 0;
    display: flex !important;
    flex-wrap: wrap;
    width: 100%;
  }
  .title-common {
    left: 0 !important;
    right: 0;
    margin: 0 !important;
    width: 50% !important;
    float: left !important;
    height: auto;
    background-color: #253288;
    top: 0;
    bottom: 0;
  }
  

  .sepical-dignol-titile-small {
    background-color: #f19f27 !important;
  }
}

@media screen and (max-width: 991px) {
  .rewamp-common-heading:before {
    left: 0;
    right: 0;
    margin: auto;
}
  .title-common {
    padding: 20px !important;
  }
  .sepical-dignol-titile-big,
  .sepical-dignol-titile-small {
    position: static;
    height: 180px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
