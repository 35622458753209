.preSale-thumb1 {
    position: absolute;
    right: 10px;
    top: 10px;
    animation: moveLeftBounce 3s linear infinite;
  }

  
/*left bounce animation*/

@keyframes moveLeftBounce {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  
    50% {
      -webkit-transform: translateX(30px);
      transform: translateX(30px);
    }
  
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  
  @keyframes MOVIE-BG {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  
    50% {
      -webkit-transform: translateX(60px);
      transform: translateX(60px);
    }
  
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  