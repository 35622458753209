input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s !important;
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    
    -webkit-animation-fill-mode: both;
}
.react-tel-input .flag-dropdown.open {
  width: 100% !important;
}
.react-tel-input .country-list {
  width: 100% !important;
}
.react-tel-input .form-control {
  width: 100%;
  border: none !important;
  border-bottom: 1px solid #CACACA !important;
}
.react-tel-input .form-control:focus {
  border-bottom: 1px solid #66afe9 !important;
  outline: 0 !important;
  box-shadow: none;
}

.phoneNum1 .react-tel-input .form-control {
  width: 100%;
  border-radius: 0 !important;
  border: none !important;
  border: 1px solid #CACACA !important;
}
.phoneNum1 .react-tel-input .form-control:focus {
  border: 1px solid #66afe9 !important;
  outline: 0 !important;
  box-shadow: none;
}
.phnInputFooter .footerPhnInput{
  height: 2.5rem !important;
  
}
.phnInputFooter .react-tel-input .form-control {
  border: none !important;
}


@-webkit-keyframes autofill {
    0%,100% {
        color: #ffffff;
        background: transparent;
    }
}

@media screen and (max-width: 700px) {
  .adjustWidthPhn {
    width: 20rem ;
    /* height: 3.5rem; */
  }
 
  .phnInput {
    padding: 8px 65px !important;
  }
}
@media screen and (min-width: 701px) {
  .adjustWidthPhn {
    /* height: 3.5rem; */
    /* width: 100% !important; */
  }
  .phnInput {
    padding: 10px 65px !important;
  }
  .phnInput1 {
    padding: 11.2px 65px !important;
  }
}
