/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Poppins Font */

@font-face {
  font-family: "Poppins-light";
  src: url("../public/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../public/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../public/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../public/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../public/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Sf-Pro Font */

@font-face {
  font-family: "SF-Pro-Regular";
  src: url("../public/fonts/SF-Pro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Semibold";
  src: url("../public/fonts/SF-Pro-Display-Semibold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SF-Pro-Bold";
  src: url("../public/fonts/SF-Pro-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "LuckiestGuyReg";
  src: url("../public/fonts/luckiestGuy/LuckiestGuy-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Font Galano */
@font-face {
  font-family: "GalanoBold";
  src: url("../public/fonts/galano/GalanoBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GalanoRegular";
  src: url("../public/fonts/galano/GalanoRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GalanoExtraBold";
  src: url("../public/fonts/galano/GalanoExtraBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GalanoSemiBold";
  src: url("../public/fonts/galano/GalanoSemiBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GalanoLight";
  src: url("../public/fonts/galano/GalanoLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Font Gilroy */

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Semi";
  src: url("../public/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600; /* Semi-bold font weight */
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../public/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}


/* font FrederickatheGreat */

@font-face {
  font-family: "FrederickatheGreat";
  src: url("../public/fonts/Fredericka_the_Great/FrederickatheGreat-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  min-width: 380px !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  margin: 0;
  font-smooth: always;
  text-rendering: optimizeLegibility !important;

  color: #323b4b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  image-rendering: -webkit-optimize-contrast !important;
  image-rendering: optimizeQuality !important;
}
svg{
  image-rendering: pixelated !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
