.chai p{
  font-size: 16px;
}

@media screen and (max-width:1199) {
  .chai p{
    font-size: 14px !important;
  }
}

.contactForm-container {
  /* width: 85%; */
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
}
.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.contactForm-container {
  position: relative;
  height: auto;
  overflow: hidden;
}

.main-div {
  width: 100%;
  /* background-color: #f0f0f0; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.fixed-div {
  /* background-color: #1c315b; */
  /* height: 39rem; */
  margin-top: 0rem;
  max-height: fit-content;
  right: 0;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 6vw;
}
.dot-shape {
  height: 12px;
  width: 12px;
}
