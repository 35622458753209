.app-cover-bg {
  background-image: url(/public/assets/app-cover-bg.webp);
  background-color: #2c3c9b;
  background-size: cover;
  background-position: bottom center;
}

.dot-bg-cover {
  background-image: url(/public/assets/dot-bg-cover.png);
  background-size: cover;
}
.dot-bg-blue {
  background-image: url(/public/assets/dot-bg-blue.png);
  /* background-repeat: repeat-y;
    background-size: contain; */
}
.feature-card {
  cursor: pointer;
}
.feature-card img {
  transition: 0.4s;
}
.feature-card:hover img {
  scale: 1.2;
}
.font-helvetica {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.partnership-independent-cta h5 {
  /* font-size: 20px; */
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin: 0px;
}
.partnership-independent-cta h4 {
  /* font-size: 30px; */
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  margin: 20px 0px;
}
