
:root {
  --text-color: hsla(210, 50%, 85%, 1);
  --shadow-color: hsla(62, 32%, 45%, 0.4);
  --btn-color: hsl(0, 0%, 0%);
  --bg-color: #515151;
}


.button7 {
  position:relative;
  padding: 8px 20px;  
  border: none;
  background: none;
  cursor: pointer;
  
  font-family: "Source Code Pro";
  font-weight: 900;
  text-transform: uppercase;
  font-size: 18px;  
  color: var(--text-color);
  transition: 0.3s;
  
  background-color: #1ba37e;
  /* box-shadow: var(--shadow-color) 2px 2px 22px; */
  border-radius: 4px; 
  z-index: 0;  
  overflow: hidden;   
}

.button7:focus {
  outline-color: transparent;
}

.right::after, .button7::after {
  content: "";
  text-align: center;
  display: block;
  position: absolute;
  width: 100%;
  white-space: nowrap;
  padding: 40px;
  pointer-events:none;
}

.button7::after{
  font-weight: 200;
  top: -30px;
  left: 30px;
} 

.right, .left {
  position: absolute;
  width: 120%;
  height: 100%;
  top: 25%;
}
.right {
  left: 66%;
}
.left {
  right: 66%;
}
.right::after {
  top: -30px;
  left: calc(-66%);
  background-color: var(--bg-color);
  color:transparent;
  transition: transform .4s ease-out;
  transform: translate(0, -90%) rotate(0deg)
}

.button7:hover .right::after {
  transform: translate(0, -47%) rotate(0deg)
}

.button7 .right:hover::after {
  transform: translate(0, -50%) rotate(-7deg)
}

.button7 .left:hover ~ .right::after {
  transform: translate(0, -50%) rotate(7deg)
}

/* bubbles */
.button7::before {
  content: '';
  pointer-events: none;
  opacity: .6;
  background:
    radial-gradient(circle at 20% 35%,  transparent 0,  transparent 2px, var(--text-color) 3px, var(--text-color) 4px, transparent 4px),
    radial-gradient(circle at 75% 44%, transparent 0,  transparent 2px, var(--text-color) 3px, var(--text-color) 4px, transparent 4px),
    radial-gradient(circle at 46% 52%, transparent 0, transparent 4px, var(--text-color) 5px, var(--text-color) 6px, transparent 6px);

  width: 100%;
  height: 300%;
  top: 0;
  left: 0;
  position: absolute;
  animation: bubbles 5s linear infinite both;
}

@keyframes bubbles {
  from {
    transform: translate();
  }
  to {
    transform: translate(0, -66.666%);
  }
}