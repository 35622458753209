.carousel-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* position: relative; */
}

.carousel-button {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.prev {
  position: absolute;
  left: 0;
}

.next {
  position: absolute;
  right: 0;
}

.carousel {
  overflow: hidden;
  width: 100%;
  max-width: 330px; /* Adjust the max-width as needed */
}

.carousel-inner {
  display: flex;
  transform: translate3d(0,0,0);
  transition: transform 0.3s ease-in-out;
}

.carousel-item {
  /* flex: 0 0 100%; */
  min-width: 100%;
  transition: 0.3s;
}

.carousel-item.active {
  transform: translate3d(0,0,0);
  opacity: 1;
  transition: opacity 0s linear 0s, visibility 0s linear 0s;
}

.carousel-item:not(.active) {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transform: translate3d(0,0,0);
  transition: opacity 0ms linear 0s, visibility 0ms linear 0s;
}
.active-img{
  filter: brightness(0) invert(1);
}

/* Indicators */

/* .indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px; 
} */

.indicator {
  /* width: 20px; 
  height: 20px; */
  /* margin: 0 5px;  */
  cursor: pointer;
  /* opacity: 0.6; */
  transition: opacity 0.3s ease-in-out;
}

.indicator.active {
  opacity: 1;
}
